<template>
  <v-container fluid>
    <v-row>

      <v-col cols="9">
        <form @submit.prevent="getExtrato()">

          <div class="d-flex justify-start">
            <div class="espacado col d-flex ">
              <label for="buscarPorLiquidacao">Data liquidação</label>
              <Calendar v-model="dataIntervaloLiquidacao" selectionMode="range"
                        @date-select="buscarPorLiquidacao" :showIcon="true"
                        dateFormat="dd/mm/yy" id="buscarPorLiquidacao"/>
            </div>
            <div class="espacado col d-flex ">
              <label for="buscarPorLiquidacaoOriginal">Data liquidação original</label>
              <Calendar v-model="dataIntervaloLiquidacaoOriginal" selectionMode="range"
                        @date-select="buscarPorLiquidacaoOriginal" :showIcon="true"
                        dateFormat="dd/mm/yy" id="buscarPorLiquidacaoOriginal"/>
            </div>
            <div class="espacado col d-flex">
              <label for="buscarPorCriacao">Data transação</label>
              <Calendar v-model="dataIntervaloCriacao" selectionMode="range"
                        @date-select="buscarPorCriacao" :showIcon="true"
                        dateFormat="dd/mm/yy" id="buscarPorCriacao"/>
            </div>
            <div class="espacado col d-flex">
              <BotaoBuscarListas @buscar="getExtrato" style="max-width: 2.5rem;" />
            </div>
          </div>

        </form>
      </v-col>

      <v-col cols="2" class="d-flex justify-end align-end">
        <div>
          <v-switch v-model="boletoPendente"
                  @change="getExtrato"
                  :label="boletoPendente ? 'boleto pendente' : 'boleto pendente'">
          </v-switch>
        </div>
      </v-col>
      <v-col class="d-flex justify-end align-end">
        <div class="espacado">
          <v-btn type="button" @click="converteCSV" icon>
                <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
        
      </v-col>
    </v-row>

    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton" class="px-16 mt-10">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-if="extrato.length > 0 && !loadingSkeleton" >
      <v-col class="custom-table rounded">


        <v-data-table group-by="data_compensacao" :items="extrato"
                      hide-default-header hide-default-footer disable-pagination ref="table">
          <!-- group custom header -->
          <template #[`group.header`]="{ items, toggle, isOpen }">

            <td @click="toggle" class="titulo white">
<!--              <i class="fas fa-arrow-right" style="font-size: 12px" v-if="!isOpen && $vuetify.breakpoint.smAndUp"/>-->
              <v-icon style="font-size: 12px" v-if="!isOpen && $vuetify.breakpoint.smAndUp"> mdi-arrow-right </v-icon>
<!--              <i class="fas fa-arrow-down" style="font-size: 12px" v-else />-->
              <v-icon style="font-size: 12px" v-else> mdi-arrow-down </v-icon>
              {{ items[0].data_compensacao | formatDate({ dateStyle: "short" })  }}
            </td>

            <td @click="toggle" colspan="2" class="white titulo">
              <span class="text-caption">Bruto</span>
              <span class="green--text" style="display: block;">
                R$ {{calculaSubTotal(items).valor | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
            <td @click="toggle" colspan="2" class="white titulo">
              <span class="text-caption">MDR</span>
              <span class="gray--text" style="display: block;">
                R$ {{ calculaSubTotal(items).totalAdm | formatMoney({ minimumFractionDigits: 2, }) }}
              </span>
            </td>
            <td @click="toggle" colspan="2" class="white titulo">
              <span class="text-caption">Antecipação</span>
              <span class="gray--text" style="display: block;">
                R$ {{ calculaSubTotal(items).totalAntec | formatMoney({ minimumFractionDigits: 2,  })}}
              </span>
            </td>
             <td @click="toggle" colspan="2" class="white titulo">
              <span class="text-caption">Liquido</span>
              <span class="green--text"  style="display: block;">
                R$ {{ calculaSubTotal(items).totalLiquido | formatMoney({ minimumFractionDigits: 2, }) }}
              </span>
            </td>
            <td @click="toggle" colspan="2" class="white titulo">
              <span class="text-caption">Multas/Juros</span>
              <span class="green--text"  style="display: block;">
                R$ {{ calculaSubTotal(items).totalMultas | formatMoney({ minimumFractionDigits: 2, }) }}
              </span>
            </td>
            <td @click="toggle" colspan="2" class="white titulo">
              <span class="text-caption">Compensado</span>
              <span class="green--text" style="display: block;">
               R$ {{ calculaSubTotal(items).totalCompensado | formatMoney({ minimumFractionDigits: 2, })}}
              </span>
            </td>
          </template>
          <!------->
          <template v-slot:[`item`]="{ index, item }" v-if="$vuetify.breakpoint.smAndUp">

            <tr v-if="index === 0" style="cursor: default">
              <th class="text-center">Status</th>
              <th class="text-center">Transacao</th>
              <th class="text-center">Valor</th>
              <th class="text-center">Tipo</th>
              <th class="text-center">Emissor</th>
              <th class="text-center">Efetuada</th>
              <th class="text-center">Comp. Ori.</th>
              <th class="text-center">Pgto</th>
              <th class="text-center">Parcela</th>
              <th class="text-center">Bruto</th>
              <th class="text-center">MDR</th>
              <th class="text-end">Antecipação</th>
              <th class="text-end">Juros/Multa</th>
              <th class="text-end">Liquido</th>
            </tr>
            <tr style="cursor: default">
              <td class="text-center">
                <v-chip class="ma-2 text-center" :color="STATUS_RECEBIVEIS[item.status].cor"
                        style="justify-content: center" label text-color="white">
                  {{ STATUS_RECEBIVEIS[item.status].texto }}
                </v-chip>
              </td>
              <td class="text-center">{{ item.transacao?.id }}</td>
              <td class="text-center">{{ item.transacao?.valor | formatMoney({ minimumFractionDigits: 2, }) }}</td>
              <td class="text-center">{{ item.transacao?.tipo }}</td>
              <td class="text-Center">{{ item.transacao?.empresa.cnpj }}</td>
              <td class="text-center">
                {{ item.transacao?.data_criacao | formatDate({ dateStyle: "short" }) }}
              </td>
              <td class="text-center">
                {{ item.data_compensacao_original | formatDate({ dateStyle: "short" }) }}
              </td>
              <td class="text-center">
                {{ item.transacao?.data_pagamento_boleto | formatDate({ dateStyle: "short" }) }}
              </td>
              <td class="text-center">
                {{ item.parcela }}/{{ item.transacao?.parcelas }}
              </td>
              <td class="text-end">
                {{ item.valor | formatMoney({ minimumFractionDigits: 2, }) }}
              </td>
              <td class="text-end">
                {{ item.valor_taxa_administracao | formatMoney({ minimumFractionDigits: 2, }) }}
              </td>
              <td class="text-end">
                {{ item.valor_taxa_antecipacao | formatMoney({ minimumFractionDigits: 2, }) }}
              </td>
              <td class="text-end">
                {{ item.transacao?.valor_pago_juros_multa | formatMoney({ minimumFractionDigits: 2, }) }}
              </td>
              <td class="text-end">
                {{ item.valor_liquido | formatMoney({ minimumFractionDigits: 2, }) }}
              </td>
            </tr>
            <tr style="cursor: default" v-if="item.transacao?.cliente">
              <td class="text-center"><i class="pi pi-reply" style="transform: rotateX(180deg);"></i></td>
              <td class="text-end"><i class="pi pi-user"></i></td>
              <td class="text-start dados-cliente" colspan="2">{{ item.transacao?.cliente ? item.transacao?.cliente?.nome: '' }}</td>
              <td class="text-start dados-cliente">{{ item.transacao?.cliente ? (item.transacao?.cliente?.cpf || item.transacao?.cliente?.cnpj): ''}}</td>
              <td class="text-start dados-cliente" colspan="3">{{ item.transacao?.cliente ? item.transacao?.cliente?.email: '' }}</td>
            </tr>
          </template>

        </v-data-table>

      </v-col>
    </v-row>

<!--    <v-row>-->
<!--      <v-col>-->
<!--        <v-pagination-->
<!--            v-model="pagination.page"-->
<!--            :length="totalPaginas"-->
<!--            @input="next"-->
<!--            :total-visible="10"-->
<!--        ></v-pagination>-->
<!--      </v-col>-->
<!--    </v-row>-->

  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import Calendar from 'primevue/calendar';
import moment from 'moment';
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "ExtratoRecebiveis",
  components:{
    Calendar,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      menuDataInicio: false,
      menuDataFim: false,
      showErrorDialog: false,
      dataIntervaloLiquidacao: [new Date(), new Date()],
      dataIntervaloCriacao: [],
      dataIntervaloLiquidacaoOriginal: [],
      competencia:'LIQUIDACAO',
      dataInicio: null,
      dataFim: null,
      extrato: [],
      boletoPendente: false,
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
    };
  },
  mounted() {
    this.buscarPorLiquidacao();
  },

  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    buscarPorCriacao(){
      this.competencia = 'CRIACAO';
      this.dataIntervaloLiquidacao = [];
      this.dataIntervaloLiquidacaoOriginal = [];
      this.getExtrato();
    },
    buscarPorLiquidacao(){
      this.competencia = 'LIQUIDACAO';
      this.dataIntervaloLiquidacaoOriginal = [];
      this.dataIntervaloCriacao = [];
      this.getExtrato();
    },
    buscarPorLiquidacaoOriginal(){
      this.competencia = 'LIQUIDACAO_ORIGINAL';
      this.dataIntervaloCriacao = [];
      this.dataIntervaloLiquidacao = [],
      this.getExtrato();
    },
    async getExtrato() {

      this.loadingSkeleton = true;

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      switch(this.competencia){
        case 'CRIACAO':
          if(!this.dataIntervaloCriacao ||
            !this.dataIntervaloCriacao[0] ||
            !this.dataIntervaloCriacao[1]){
            return;
          }

          if(this.dataIntervaloCriacao[0]){
            this.dataInicio = moment(this.dataIntervaloCriacao[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloCriacao[1]){
            this.dataFim = moment(this.dataIntervaloCriacao[1]).format('YYYY-MM-DD');
          }
        break;
        case 'LIQUIDACAO_ORIGINAL':
          if(!this.dataIntervaloLiquidacaoOriginal ||
            !this.dataIntervaloLiquidacaoOriginal[0] ||
            !this.dataIntervaloLiquidacaoOriginal[1]){
            return;
          }

          if(this.dataIntervaloLiquidacaoOriginal[0]){
            this.dataInicio = moment(this.dataIntervaloLiquidacaoOriginal[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloLiquidacaoOriginal[1]){
            this.dataFim = moment(this.dataIntervaloLiquidacaoOriginal[1]).format('YYYY-MM-DD');
          }

        break;
        default:

          if(!this.dataIntervaloLiquidacao ||
            !this.dataIntervaloLiquidacao[0] ||
            !this.dataIntervaloLiquidacao[1]){
            return;
          }

          if(this.dataIntervaloLiquidacao[0]){
            this.dataInicio = moment(this.dataIntervaloLiquidacao[0]).format('YYYY-MM-DD');
          }

          if(this.dataIntervaloLiquidacao[1]){
            this.dataFim = moment(this.dataIntervaloLiquidacao[1]).format('YYYY-MM-DD');
          }

      }


      return this.$axios.get(`/relatorio/transacao/empresa/${this.empresaAtual.id}/recebiveis`,
          {
            params: {
              "data-inicio": this.dataInicio,
              "data-fim": this.dataFim,
              "boleto-pendente": this.boletoPendente,
              "competencia": this.competencia
            },
          }
        )
        .then((response) => {
          this.extrato = response.data.data;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.extrato = [];
          this.dataIntervaloLiquidacaoOriginal = [];
          this.dataIntervaloLiquidacao = [];
          this.dataIntervaloCriacao = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data,
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    calculaSubTotal(items = []) {
      let valor = 0;
      let totalAdm = 0;
      let totalAntec = 0;
      let totalCompensado = 0;
      let totalLiquido = 0;
      let totalMultas = 0;

      items.forEach((item) => {
        valor += item.valor;
        totalAdm += item.valor_taxa_administracao;
        totalAntec += item.valor_taxa_antecipacao;
        totalLiquido += item.valor_liquido
        totalMultas += item.transacao?.valor_pago_juros_multa ? item.transacao?.valor_pago_juros_multa: 0.00

        if(item.status == 'LIQUIDADO' || item.status == 'PAGO'){
          totalCompensado += item.valor_liquido;
        }

      });

      return {
        valor,
        totalAdm,
        totalAntec,
        totalCompensado,
        totalLiquido,
        totalMultas
      };
    },
    converteCSV() {
      const copyExtrato = this.extrato.map((item) => {
        const obj = { ... item};

        obj.data_compensacao = obj.data_compensacao.split('T')[0];
        obj.data_compensacao_original = obj.data_compensacao_original.split('T')[0];

        obj.id_transacao = obj.transacao?.id;
        obj.tipo_transacao = obj.transacao?.tipo;
        obj.status_transacao = obj.transacao?.status;
        obj.data_transacao = obj.transacao?.data_criacao.split('T')[0];
        obj.hora_transacao = obj.transacao?.data_criacao.split('T')[1];
        obj.valor_transacao = this.parseFloatToMoney(obj.transacao?.valor);
        obj.parcelas_transacao = obj.transacao?.parcelas;
        obj.data_pagamento_boleto = obj.transacao?.data_pagamento_boleto?.split('T')[0];
        obj.valor_pago_juros_multa = this.parseFloatToMoney(obj.transacao?.valor_pago_juros_multa)

        obj.valor = this.parseFloatToMoney(obj.valor);
        obj.valor_taxa_administracao = this.parseFloatToMoney(obj.valor_taxa_administracao);
        obj.valor_taxa_antecipacao = this.parseFloatToMoney(obj.valor_taxa_antecipacao);
        obj.valor_liquido = this.parseFloatToMoney(obj.valor_liquido);

        obj.cnpj_empresa_transacao = obj.transacao?.empresa.cnpj;
        obj.nome_empresa_transacao = obj.transacao?.empresa.razao_social;
        obj.documento_cliente_transacao = (obj.transacao?.cliente ? (obj.transacao?.cliente?.cpf || obj.transacao?.cliente?.cnpj):'');
        obj.nome_cliente_transacao = (obj.transacao?.cliente ? obj.transacao?.cliente?.nome:'');

        delete obj.id;
        delete obj.recebedor;
        delete obj.transacao;
        delete obj.ur_id;

        return obj;
      });
      this.toCSVeDownload("extrato-recebiveis.csv", copyExtrato);
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getExtrato();
    },
  },
  computed: {
    ...mapState(["empresaAtual","user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
};
</script>

<style scoped>
.titulo{
  font-size: 1.2rem !important;
}
.dados-cliente{
  font-size: 0.8rem !important;
}
.v-row-group__header td {
  background-color: #f5f2f2 !important;
  /* color: #495057; */
}

.export-icon {
  font-size: 25px;
  transition: all 0.2s linear;
}

.export-icon:hover {
  transform: scale(1.3);
}

.col{
  flex-direction: column;
  justify-content: end;
}

label {
    font-size: 0.8rem;
    color: #4b4b4b;
}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }


  tr {
    max-width: 100%;
  }
  td {
    display: flex;
    justify-content: space-between;
  }

}
</style>
