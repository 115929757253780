<template>
  <div v-if="venda" class="mt-n2">
    <v-row class="no-gutters d-flex flex-wrap">
      <v-col cols="3" class="d-flex">
        <v-card elevation="0" min-height="100" outlined class="py-3 billing-container flex-grow-1">
          <span class="billing-title text-caption">Venda #{{ venda.id }}</span>
          <v-row class="no-gutters mb-n5">
            <v-col cols="12" lg="4">
              <v-chip color="green" class="white--text mr-3" v-if="venda.pago">
                Pago
              </v-chip>
              <v-chip color="red" class="white--text mr-3" v-if="vendaCancelada">
                Cancelado
              </v-chip>
            </v-col>
            <v-col cols="12" lg="8">
              <v-row class="no-gutters">
                <v-col class="d-flex justify-end coluna-botoes-cobranca">
                  <Button
                      :disabled="vendaCancelada"
                      icon="pi pi-file"
                      label="Nota Fiscal"
                      @click="openNotaFiscal"
                      class="p-button-outlined p-button-success btn-nf"
                  />

                  <Button
                      icon="pi pi-copy"
                      label="Fechar"
                      @click="goToVendas"
                      class="p-button-outlined btn-fechar"
                  />


                </v-col>
              </v-row>

            </v-col>
          </v-row>

          <v-row class="no-gutters mt-5">
            <v-col v-if="venda.data_criacao" cols="4">
              <v-card-title class="text-caption"><b>Criação</b></v-card-title>
              <v-card-subtitle class="text-caption">
                {{
                  venda.data_criacao
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                }}
              </v-card-subtitle>
            </v-col>
            <v-col v-if="venda.data_atualizacao" cols="4">
              <v-card-title class="text-caption"><b>Atualização</b></v-card-title>
              <v-card-subtitle class="text-caption">
                {{
                  venda.data_atualizacao
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                }}
              </v-card-subtitle>
            </v-col>
            <v-col v-if="venda.data_finalizada" cols="4">
              <v-card-title class="text-caption"><b>Finalização</b></v-card-title>
              <v-card-subtitle class="text-caption">
                {{
                  venda.data_finalizada
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                }}
              </v-card-subtitle>
            </v-col>
            <v-col v-if="venda.data_cancelamento" cols="4">
              <v-card-title class="text-caption"><b>Cancelamento</b></v-card-title>
              <v-card-subtitle class="text-caption">
                {{
                  venda.data_cancelamento
                      | formatDate({ dateStyle: "short", timeStyle: "short" })
                }}
              </v-card-subtitle>
            </v-col>
          </v-row>

          <v-row class="no-gutters">
            <v-col v-if="venda.operador_criacao" cols="4">
              <v-card-title class="text-caption"><b>Usuário Criação</b></v-card-title>
              <v-card-subtitle class="text-caption">
                {{ venda.operador_criacao.nome }}
              </v-card-subtitle>
            </v-col>
            <v-col v-if="venda.operador_atualizacao" cols="4">
              <v-card-title class="text-caption"><b>Usuário Atualização</b></v-card-title>
              <v-card-subtitle class="text-caption">
                {{ venda.operador_atualizacao.nome }}
              </v-card-subtitle>
            </v-col>
          </v-row>

        </v-card>
      </v-col>
      <v-col cols="3" class="d-flex">
        <v-card min-height="100" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
          <span class="billing-title text-caption">Cliente</span>
          <v-row v-if="venda.cliente" dense>
            <v-col>
              <v-card-title class="text-caption"><b>{{ venda.cliente.nome }}</b></v-card-title>
              <v-card-subtitle class="text-caption">
                <span>{{ $masks.cpf(venda.cliente.cpf) }} | {{ venda.cliente.email }} | {{ $masks.telefone(venda.cliente.telefone_celular) }}</span><br>
                <span class="text-caption" v-if="venda.cliente.cep">
                                  {{ venda.cliente.endereco }},
                                  {{ venda.cliente.numero }} -
                                  {{ venda.cliente.cidade }} ,
                                  {{ venda.cliente.uf }}
                                </span>
              </v-card-subtitle>
            </v-col>
          </v-row>
          <v-row v-else dense>
            <v-col>
              <v-card-title class="text-caption"><b>Nenhum cliente informado.</b></v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-flex">
        <v-card min-height="100" elevation="0" outlined class="py-0 px-0 billing-container flex-grow-1">
          <span class="billing-title text-caption">Prestador</span>
          <v-row v-if="venda.prestador" dense>
            <v-col>
              <v-card-title class="text-caption"><b>{{ venda.prestador.nome_fantasia }}</b></v-card-title>
              <v-card-subtitle class="text-caption">
                <span>{{ venda.prestador.razao_social }}</span><br>
                <span>{{ $masks.cnpj(venda.prestador.cnpj) }}</span>
              </v-card-subtitle>
            </v-col>
          </v-row>
          <v-row v-else dense>
            <v-col>
              <v-card-title class="text-caption"><b>Nenhum prestador informado.</b></v-card-title>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
      <v-col cols="3" class="d-flex">
        <v-card min-height="100" elevation="0" outlined class="billing-container flex-grow-1">
          <span class="billing-title text-caption">Detalhes</span>
          <v-row dense class="mt-n3">
            <v-col cols="6">
              <v-card-title class="text-caption"><b>Adicional Prestador</b></v-card-title>
              <v-card-subtitle class="text-caption">
                <span>R$ {{ $masks.dinheiro(venda.adicional_prestador.toFixed(2)) }}</span>
              </v-card-subtitle>
            </v-col>
            <v-col cols="6">
              <v-card-title class="text-caption"><b>Taxa de entrega</b></v-card-title>
              <v-card-subtitle class="text-caption">
                <span>R$ {{ $masks.dinheiro(venda.taxa_entrega.toFixed(2)) }}</span>
              </v-card-subtitle>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="no-gutters mt-n7 d-flex flex-wrap">
      <v-col cols="6" class="d-flex">
        <v-row>
          <v-col v-if="venda.pagamentos && venda.pagamentos.length > 0" cols="12">
            <v-card elevation="0" outlined class="py-3 billing-container flex-grow-1">
              <span class="billing-title text-caption">Formas de pagamento</span>
              <v-row class="mx-auto">
                <v-col>
                  <DataTable
                      :value="venda.pagamentos"
                      responsiveLayout="stack"
                      breakpoint="960px"
                      scrollHeight="flex-row"
                  >
                    <Column header="Data">
                      <template #body="slot">
                        {{
                          slot.data.data_criacao
                              | formatDate({ dateStyle: "short", timeStyle: "short" })
                        }}
                      </template>
                    </Column>
                    <Column header="Pagamento">
                      <template #body="slot">
                        {{ slot.data.forma_pagamento }}
                      </template>
                    </Column>
                    <Column header="Transação">
                      <template #body="slot">
                        <span v-if="slot.data.transacao" class="text-caption">{{ slot.data.transacao.id }}</span>
                        <span v-else class="text-caption">-</span>
                      </template>
                    </Column>
                    <Column header="Valor">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valorBruto.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column header="Desconto">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.desconto.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column header="Total">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                      </template>
                    </Column>
                  </DataTable>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col v-if="venda.itens && venda.itens.length > 0" cols="12" class="d-flex" :class="venda.pagamentos && venda.pagamentos.length > 0 ? 'mt-n12' : ''">
            <v-card elevation="0" outlined class="py-3 billing-container flex-grow-1">
              <span class="billing-title text-caption">Itens</span>
              <v-row class="mx-auto">
                <v-col>
                  <DataTable
                      :value="venda.itens"
                      responsiveLayout="stack"
                      breakpoint="960px"
                      scrollHeight="flex-row"
                  >
                    <Column field="quantidade" header="Quant." />
                    <Column header="Item">
                      <template #body="slot">
                        <div v-if="slot.data.servico">
                          <p>{{ slot.data.servico.sku }} - {{ truncateNomeItem(slot.data.servico.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.servico.descricao) }}</p>
                        </div>
                        <div v-if="slot.data.produto">
                          <p>{{ slot.data.produto.codigo_barras }} - {{ truncateNomeItem(slot.data.produto.nome) }}</p>
                          <p class="text-caption">{{ truncateNomeItem(slot.data.produto.descricao) }}</p>
                        </div>
                      </template>
                    </Column>
                    <Column header="Valor">
                      <template #body="slot">
                        {{ $masks.dinheiro(slot.data.valor.toFixed(2)) }}
                      </template>
                    </Column>
                    <Column header="Total">
                      <template #body="slot">
                        {{
                          (slot.data.quantidade * slot.data.valor)
                              | formatMoney({ minimumFractionDigits: 2 })
                        }}
                      </template>
                    </Column>
                  </DataTable>

                  <div
                      style="border-top: 1px solid gray"
                      class="text-end mt-4"
                  >
                                          <span class="text-caption green--text" style="display: inline-block;"
                                          >R$</span
                                          >
                    <span class="text-h3 green--text">{{
                        totalItens | formatMoney({ minimumFractionDigits: 2 })
                      }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col v-if="venda.splits && venda.splits.length > 0" cols="6" class="d-flex">
        <v-card elevation="0" outlined class="py-3 billing-container flex-grow-1">
          <span class="billing-title text-caption">Recebedores</span>
          <v-row>
            <v-col class="text-center">
              <div>
                <div
                    style="width: 250px"
                    class="green white--text rounded-lg pa-3"
                >
                  <span style="font-size: 2rem">
                    R$
                    {{ venda.total | formatMoney({ minimumFractionDigits: 2 }) }}
                  </span>
                </div>

                <v-timeline dense>
                  <v-timeline-item
                      color="green"
                      v-for="split in venda.splits"
                      :key="split.recebedor.id"
                  >
                    <template v-slot:icon></template>
                    <v-card>
                      <v-card-text>
                        <v-row>
                          <v-col class="text-center d-flex flex-column">
                            <span class="font-weight-bold text-caption">
                              {{ split.recebedor.nome_fantasia }}
                            </span>
                            <span class="text-caption font-weight-bold">
                              {{ split.recebedor.razao_social }}
                            </span>
                            <span class="text-caption font-weight-bold">
                              {{ $masks.cnpj(split.recebedor.cnpj) }}
                            </span>
                          </v-col>
                        </v-row>


                        <v-row dense>
                          <v-col class="col-3 text-start">
                            <span class="text-caption">Paga Taxa Transação </span>
                          </v-col>
                          <v-col class="text-start">
                            <span class="font-weight-bold text-caption">{{ split.paga_taxa_transacao | formatBoolean }}</span>
                          </v-col>
                          <v-col class="col-3 text-start">
                            <span class="text-caption">Paga Resto Divisão </span>
                          </v-col>
                          <v-col class="text-start">
                            <span class="font-weight-bold text-caption">{{ split.paga_resto_divisao_taxa | formatBoolean }}</span>
                          </v-col>
                          <v-col class="text-start">
                            <span class="text-caption">Valor </span>
                          </v-col>
                          <v-col class="text-start">
                            <span class="font-weight-bold text-caption">
                              {{ split.valor | formatMoney({ minimumFractionDigits: 2 }) }}
                              {{ split.metodo === "PERCENTUAL" ? "%" : null }}
                            </span>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-timeline-item>
                </v-timeline>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>


    <DialogListaNotaFiscal
        :notas="venda.notasfiscais"
        :itensVenda="venda.itens"
        :idVenda="idVenda"
        v-if="showNota"
        :showDialog="showNota"
        @cancela-dialog="fechaDialogNotaFiscal"
        @recarregar-notas="getVenda"
    />

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import DialogListaNotaFiscal from "@/components/Dialogs/DialogListaNotaFiscal.vue";


export default {
  name: "DetalhesVenda",
  components: {
    DialogListaNotaFiscal,
    Button,
    DataTable,
    Column,
  },
  props: {
    idVenda: Number
  },
  data() {
    return {
      venda: null,
      vendaCancelada: false,
      showNota: false
    };
  },
  mounted() {
    this.getVenda();
  },
  computed: {
    ...mapState(["user", "empresaAtual"]),
    totalItens() {
      if (!this.venda.itens) return 0;

      const total = this.venda.itens.reduce(
          (prev, curr) => (prev += curr.quantidade * curr.valor),
          0
      );
      return total.toFixed(2);
    },
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    getVenda() {
      this.$axios
          .get(`/venda/empresa/${this.empresaAtual.id}/venda/${this.idVenda}`)
          .then(response => {
            this.venda = response.data;
            this.vendaCancelada = !!this.venda.data_cancelamento;
          })
          .catch(error => {
            this.openDialogMessage({
              type: "error",
              title: "Erro - Consultar Venda",
              text: error.response.data
            });
          });
    },

    truncateNomeItem(nome) {
      if (nome.length > 100) {
        return nome.substring(0, 100) + '...';
      } else {
        return nome;
      }
    },

    openNotaFiscal() {
      if(this.venda.notasfiscais && this.venda.notasfiscais.length > 0) {

        this.showNota = true;

      } else {

        this.openDialogConfirm({
          title: "Atenção",
          text: "Deseja gerar nota dessa venda?",
          confirma: this.gerarNF,
          cancela: this.closeDialogConfirm
        });

      }
    },

    fechaDialogNotaFiscal() {
      this.showNota = false;
    },

    goToVendas() {
      this.$router.push({ name: 'VendasPdv' });
    }






  }
};
</script>

<style scoped>
.billing-container {
  padding: 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.btn-nf, .btn-fechar {
  margin: 0.5rem;
  height: 2rem;
}

.btn-nf {
  color: rgb(0, 128, 128)!important;
}

.btn-fechar {
  color: black!important;
}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
</style>
