<template>
  <v-container fluid>
    <!-- Barra de Tabs -->
    <v-row class="mb-4">
      <v-col>
        <v-card outlined>
          <v-tabs v-model="tab" align-with-title @change="handleChangeTab">
            <v-tabs-slider color="secondary"></v-tabs-slider>
            <v-tab :disabled="etapaTransferencia === 1">
              <v-icon class="mr-3">mdi-warehouse</v-icon>
              Controle de Estoque
            </v-tab>
            <v-tab>
              <v-icon class="mr-3">mdi-swap-horizontal</v-icon>
              Transferência
            </v-tab>
            <v-tab disabled>
              <v-icon class="mr-3">mdi-clipboard-text</v-icon>
              Inventário
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>

    <v-row class="mt-n7">
      <!-- Coluna Lateral Fixa com Estilização -->
      <v-col cols="2" v-if="showColunaEstoques">
        <v-card class="pa-2">
          <v-list v-if="tiposEstoqueEmpresa.length > 0">
            <v-list-item-group
              v-model="selectedLocation"
              @change="onEstoqueChange"
            >
              <v-list-item
                v-for="item in tiposEstoqueEmpresa"
                :key="item.id"
                :value="item.id"
                active-class="estoque-selecionado"
              >
                <v-list-item-icon class="ml-n3">
                  <v-icon color="yellow" v-if="item.principal">mdi-star</v-icon>
                </v-list-item-icon>
                <v-list-item-content class="ml-n6">
                  <v-list-item-title>{{ item.nome }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon @click.stop="editarEstoque(item)">
                  <v-icon color="grey darken-1">mdi-pencil</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-item-group>
          </v-list>

          <div class="d-flex justify-center mt-3">
            <v-btn
              fab
              dark
              small
              color="primary"
              class="elevation-1"
              @click="abrirDialogNovoEstoque"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <!-- Conteúdo Aba Controle de Estoque -->
      <v-col :cols="showColunaEstoques ? 10 : 12">
        <v-card class="pa-4" outlined>
          <div v-if="tab === 0 && !showMovimentar">
            <v-row>
              <v-col cols="10" class="d-flex align-center">
                <div class="espacado">
                  <Chips
                      ref="chipcomponente"
                      outlined
                      clearable
                      v-model="filtro"
                      separator=","
                      @add="getProdutosEstoque({ id: selectedLocation } , true)"
                      @remove="getProdutosEstoque({ id: selectedLocation}, true)"
                      placeholder="Filtro por nome ou EAN do produto"
                  />
                </div>
                <div class="espacado">
                  <BotaoBuscarListas @buscar="buscar" />
                </div>
              </v-col>
              <v-col cols="2" class="text-right">
                <v-btn
                    outlined
                    color="blue"
                    class="elevation-0"
                    @click="abrirDialogMovimentar"
                >
                  <v-icon left color="blue">mdi-swap-horizontal</v-icon>
                  Movimentar
                </v-btn>
              </v-col>
            </v-row>

            <v-data-table
              :hide-default-footer="true"
              :headers="headers"
              :items="produtosEstoque"
              class="elevation-0 mt-3"
            >
              <template v-slot:[`item.produto.balanca`]="{ item }">
                <span>{{ item.produto.balanca ? "SIM" : "NÃO" }}</span>
              </template>
            </v-data-table>
            <v-pagination
              class="mt-3"
              v-model="pagination.page"
              :length="totalPaginas"
              @input="next"
              :total-visible="10"
            ></v-pagination>
          </div>

          <!-- Conteúdo Aba Transferencia -->

          <div v-else-if="tab === 1 && !showMovimentar">
            <!-- Primeira Etapa: Lista de Produtos -->
            <div v-if="etapaTransferencia === 0">
              <v-row align="center">
                <v-col cols="10" class="d-flex align-center">
                  <div class="espacado mr-2">
                    <Chips
                      ref="chipcomponente"
                      outlined
                      clearable
                      v-model="filtro"
                      separator=","
                      @add="getProdutosEstoque({ id: selectedLocation }, true)"
                      @remove="getProdutosEstoque({ id: selectedLocation }, true)"
                      placeholder="Filtro por nome ou EAN do produto"
                    />
                  </div>
                  <div class="espacado">
                    <BotaoBuscarListas @buscar="buscar" />
                  </div>
                </v-col>

                <v-col cols="2" class="text-right">
                  <v-btn
                    outlined
                    color="blue"
                    class="elevation-0"
                    @click="abrirDialogImportarCSV"
                  >
                    <v-icon left color="blue">mdi-import</v-icon>
                    Importar
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="mt-n6">
                <v-col cols="6">
                  <v-card outlined>
                    <v-data-table
                      :hide-default-footer="true"
                      :headers="headersATransferir"
                      :items="produtosATransferir"
                      class="elevation-0 mt-3"
                      @click:row="adicionarProduto"
                    >
                    </v-data-table>
                    <v-pagination
                      class="mt-3"
                      v-model="pagination.page"
                      :length="totalPaginas"
                      @input="next"
                      :total-visible="10"
                    ></v-pagination>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card outlined>
                    <v-data-table
                      :hide-default-footer="true"
                      :headers="headersTransferido"
                      :items="produtosTransferido"
                      class="elevation-0 mt-3"
                    >
                      <template v-slot:[`item.quantidade`]="{ item }">
                        <div class="div-quantidade">
                          <InputNumber
                            mode="decimal"
                            :min="1"
                            v-model="item.quantidade"
                            showButtons
                            buttonLayout="horizontal"
                            :step="1"
                            decrementButtonClass="p-button-danger"
                            incrementButtonClass="p-button-success"
                            incrementButtonIcon="pi pi-plus"
                            decrementButtonIcon="pi pi-minus"
                            inputClass="text-center"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.remover`]="{ item }">
                        <v-btn
                          icon
                          small
                          outlined
                          color="blue"
                          @click="removerProduto(item)"
                        >
                          <v-icon small color="blue">mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn class="primary" @click="avancarTransferencia">
                    Avançar
                  </v-btn>
                </v-col>
              </v-row>
            </div>


            <div v-else-if="etapaTransferencia === 1">
              <!-- Segunda Etapa: Escolha da Empresa e Estoque -->
              <v-row>
                <v-col cols="6">
                  <v-card
                    elevation="0"
                    outlined
                    class="py-0 px-0 billing-container"
                  >
                    <span class="billing-title text-caption"
                      >Itens de transferência</span
                    >
                    <v-card-title>
                      {{ empresaAtual.nome_fantasia }}
                    </v-card-title>
                    <v-card-subtitle>
                      {{
                        tiposEstoqueEmpresa.find(
                          item => item.id === selectedLocation
                        )?.nome
                      }}
                    </v-card-subtitle>
                    <v-card-text>
                      <v-data-table
                        :hide-default-footer="true"
                        :headers="headersListTransferido"
                        :items="produtosTransferido"
                        class="elevation-0 mt-3"
                      />
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card
                    elevation="0"
                    outlined
                    class="py-0 px-0 billing-container"
                  >
                    <span class="billing-title text-caption">Destinatário</span>
                    <v-card-text>
                      <v-row dense>
                        <v-col class="d-flex">
                          <AutoComplete
                            class="autocomplete-input"
                            v-model="empresaSelecionada"
                            :suggestions="empresasFiltradas"
                            @complete="searchEmpresa"
                            forceSelection
                            :field="itemTextEmpresa"
                            placeholder="Pesquise aqui..."
                          >
                            <template #item="slotProps">
                              <div>
                                <div>{{ slotProps.item.nome }}</div>
                                <div>{{ slotProps.item.razao_social }}</div>
                                <div>
                                  CPF/CNPJ:
                                  {{ $masks.cpf(slotProps.item.cpf) }}
                                  {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                                  {{
                                    $masks.telefone(
                                      slotProps.item.telefone_celular
                                    )
                                  }}
                                  E-mail: {{ slotProps.item.email }}
                                </div>
                              </div>
                            </template>
                          </AutoComplete>

                          <div
                            v-if="empresaSelecionada"
                            class="div-clearable"
                            @click="empresaSelecionada = null"
                          >
                            <v-icon>mdi-close</v-icon>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <div
                            v-if="
                              empresaSelecionada &&
                                empresaSelecionada.razao_social
                            "
                            class="d-flex flex-column custom-card"
                          >
                            <span>
                              {{ empresaSelecionada.nome_fantasia }}
                            </span>
                            <span>
                              {{ empresaSelecionada.razao_social }}
                            </span>
                            <span class="text-caption">
                              CNPJ {{ empresaSelecionada.cnpj }}
                            </span>
                            <span class="text-caption">
                              {{ empresaSelecionada.telefone }}
                            </span>
                            <span class="text-caption">
                              {{ empresaSelecionada.endereco }},
                              {{ empresaSelecionada.numero }} -
                              {{ empresaSelecionada.cidade }} ,
                              {{ empresaSelecionada.uf }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-select
                        v-model="estoqueSelecionado"
                        :items="estoques"
                        item-text="text"
                        item-value="value"
                        label="Selecione o Estoque"
                        outlined
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6" class="text-left">
                  <v-btn class="secondary" @click="voltarTransferencia">
                    Voltar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn :disabled="!estoqueSelecionado" class="primary" @click="confirmarTransferencia">
                    Confirmar
                  </v-btn>
                </v-col>
              </v-row>
            </div></div>

          <div v-else-if="tab === 2 && !showMovimentar">
            <!-- Conteúdo da Aba Inventário -->

            <!-- Primeira Etapa: Escolha Empresa e Estoque -->
            <div v-if="etapaInventario === 0">
              <v-row class="mx-auto">
                <v-col cols="12">
                  <v-card
                      elevation="0"
                      outlined
                      class="py-0 px-0 billing-container"
                  >
                    <span class="billing-title text-caption">Selecione a empresa e o estoque</span>
                    <v-card-text>
                      <v-row dense>
                        <v-col class="d-flex">
                          <AutoComplete
                              class="autocomplete-input"
                              v-model="empresaSelecionada"
                              :suggestions="empresasFiltradas"
                              @complete="searchEmpresa"
                              forceSelection
                              :field="itemTextEmpresa"
                              placeholder="Pesquise aqui..."
                          >
                            <template #item="slotProps">
                              <div>
                                <div>{{ slotProps.item.nome }}</div>
                                <div>{{ slotProps.item.razao_social }}</div>
                                <div>
                                  CPF/CNPJ:
                                  {{ $masks.cpf(slotProps.item.cpf) }}
                                  {{ $masks.cnpj(slotProps.item.cnpj) }} Tel:
                                  {{
                                    $masks.telefone(
                                        slotProps.item.telefone_celular
                                    )
                                  }}
                                  E-mail: {{ slotProps.item.email }}
                                </div>
                              </div>
                            </template>
                          </AutoComplete>

                          <div
                              v-if="empresaSelecionada"
                              class="div-clearable"
                              @click="empresaSelecionada = null"
                          >
                            <v-icon>mdi-close</v-icon>
                          </div>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col>
                          <div
                              v-if="
                              empresaSelecionada &&
                                empresaSelecionada.razao_social
                            "
                              class="d-flex flex-column custom-card"
                          >
                            <span>
                              {{ empresaSelecionada.nome_fantasia }}
                            </span>
                            <span>
                              {{ empresaSelecionada.razao_social }}
                            </span>
                            <span class="text-caption">
                              CNPJ {{ empresaSelecionada.cnpj }}
                            </span>
                            <span class="text-caption">
                              {{ empresaSelecionada.telefone }}
                            </span>
                            <span class="text-caption">
                              {{ empresaSelecionada.endereco }},
                              {{ empresaSelecionada.numero }} -
                              {{ empresaSelecionada.cidade }} ,
                              {{ empresaSelecionada.uf }}
                            </span>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-text>
                      <v-select
                          v-model="estoqueSelecionado"
                          :items="estoques"
                          item-text="text"
                          item-value="value"
                          label="Selecione o Estoque"
                          outlined
                      ></v-select>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn class="primary" @click="avancarInventario">
                    Avançar
                  </v-btn>
                </v-col>
              </v-row>
            </div>

            <div v-else-if="etapaInventario === 1">
              <!-- Segunda Etapa: Lista de Produtos -->
              <v-row align="center">
                <v-col cols="12" class="d-flex align-center">
                  <div class="espacado mr-2">
                    <Chips
                        ref="chipcomponente"
                        outlined
                        clearable
                        v-model="filtro"
                        separator=","
                        @add="getProdutosEstoque({ id: selectedLocation }, true)"
                        @remove="getProdutosEstoque({ id: selectedLocation }, true)"
                        placeholder="Filtro por nome ou EAN do produto"
                    />
                  </div>
                  <div class="espacado">
                    <BotaoBuscarListas @buscar="buscar" />
                  </div>
                  <div class="espacado">
                    <div v-if="empresaSelecionada && empresaSelecionada.razao_social" class="d-flex">
                    <span class="text-caption">
                      Empresa:
                      <b>
                        {{
                          empresaSelecionada.nome_fantasia
                        }}
                      </b>
                    </span>
                      <span class="text-caption ml-3">
                      Estoque:
                      <b>
                        {{
                          estoques.filter((item) => item.value === estoqueSelecionado)[0].text
                        }}
                      </b>
                    </span>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-n6">
                <v-col cols="6">
                  <v-card outlined>
                    <v-data-table
                        :hide-default-footer="true"
                        :headers="headersATransferir"
                        :items="produtosATransferir"
                        class="elevation-0 mt-3"
                        @click:row="adicionarProduto"
                    >
                    </v-data-table>
                    <v-pagination
                        class="mt-3"
                        v-model="pagination.page"
                        :length="totalPaginas"
                        @input="next"
                        :total-visible="10"
                    ></v-pagination>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card outlined>
                    <v-data-table
                        :hide-default-footer="true"
                        :headers="headersTransferido"
                        :items="produtosTransferido"
                        class="elevation-0 mt-3"
                    >
                      <template v-slot:[`item.quantidade`]="{ item }">
                        <div class="div-quantidade">
                          <InputNumber
                              mode="decimal"
                              :min="1"
                              v-model="item.quantidade"
                              showButtons
                              buttonLayout="horizontal"
                              :step="1"
                              decrementButtonClass="p-button-danger"
                              incrementButtonClass="p-button-success"
                              incrementButtonIcon="pi pi-plus"
                              decrementButtonIcon="pi pi-minus"
                              inputClass="text-center"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.remover`]="{ item }">
                        <v-btn
                            icon
                            small
                            outlined
                            color="blue"
                            @click="removerProduto(item)"
                        >
                          <v-icon small color="blue">mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col cols="6" class="text-left">
                  <v-btn class="secondary" @click="voltarInventario">
                    Voltar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn :disabled="!estoqueSelecionado" class="primary" @click="confirmarInventario">
                    Confirmar
                  </v-btn>
                </v-col>
              </v-row>

            </div>

          </div>

          <div v-if="showMovimentar">
            <!-- Conteúdo da Aba Movimentar -->

            <div>
              <v-row align="center">
                <v-col cols="12" class="d-flex align-center">
                  <div class="espacado mr-2">
                    <Chips
                        ref="chipcomponente"
                        outlined
                        clearable
                        v-model="filtro"
                        separator=","
                        @add="getProdutosEstoque({ id: selectedLocation }, true)"
                        @remove="getProdutosEstoque({ id: selectedLocation }, true)"
                        placeholder="Filtro por nome ou EAN do produto"
                    />
                  </div>
                  <div class="espacado">
                    <BotaoBuscarListas @buscar="buscar" />
                  </div>
                  <div class="espacado">
                    <div class="d-flex">
                    <span class="text-caption">
                      Empresa:
                      <b>
                        {{
                          empresaAtual.nome_fantasia
                        }}
                      </b>
                    </span>
                      <span class="text-caption ml-3">
                      Estoque:
                      <b>
                        {{
                          tiposEstoqueEmpresa.filter((item) => item.id === selectedLocation)[0].nome
                        }}
                      </b>
                    </span>
                      <span class="text-caption ml-3">
                      Movimentação:
                      <b>
                        {{
                          tiposMovimentacoes.filter((item) => item.value === tipoMovimentacao)[0].text
                        }}
                      </b>
                    </span>
                    </div>
                  </div>
                </v-col>
              </v-row>

              <v-row class="mt-n6">
                <v-col cols="6">
                  <v-card outlined>
                    <v-data-table
                        :hide-default-footer="true"
                        :headers="headersATransferir"
                        :items="produtosATransferir"
                        class="elevation-0 mt-3"
                        @click:row="adicionarProduto"
                    >
                    </v-data-table>
                    <v-pagination
                        class="mt-3"
                        v-model="pagination.page"
                        :length="totalPaginas"
                        @input="next"
                        :total-visible="10"
                    ></v-pagination>
                  </v-card>
                </v-col>

                <v-col cols="6">
                  <v-card outlined>
                    <v-data-table
                        :hide-default-footer="true"
                        :headers="headersTransferido"
                        :items="produtosTransferido"
                        class="elevation-0 mt-3"
                    >
                      <template v-slot:[`item.quantidade`]="{ item }">
                        <div class="div-quantidade">
                          <InputNumber
                              mode="decimal"
                              :min="1"
                              v-model="item.quantidade"
                              showButtons
                              buttonLayout="horizontal"
                              :step="1"
                              decrementButtonClass="p-button-danger"
                              incrementButtonClass="p-button-success"
                              incrementButtonIcon="pi pi-plus"
                              decrementButtonIcon="pi pi-minus"
                              inputClass="text-center"
                          />
                        </div>
                      </template>
                      <template v-slot:[`item.remover`]="{ item }">
                        <v-btn
                            icon
                            small
                            outlined
                            color="blue"
                            @click="removerProduto(item)"
                        >
                          <v-icon small color="blue">mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>

              <v-row class="mt-n3">
                <v-col cols="6" class="text-left">
                  <v-btn class="secondary" @click="voltarMovimentar">
                    Voltar
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn class="primary" @click="confirmarMovimentar">
                    Confirmar
                  </v-btn>
                </v-col>
              </v-row>

            </div>

          </div>
        </v-card>
      </v-col>
    </v-row>

    <DialogAddEstoqueLoja
      :dialog.sync="dialogEstoqueLoja"
      :estoqueParaEdicao="estoqueParaEdicao"
      @saveDialogEstoqueLoja="saveDialogEstoqueLoja"
    />

    <DialogImportarCSV
      :dialog.sync="dialogImportarCSV"
      @importarCSV="processarImportacao"
      @erroImportarCSV="mostrarErroImportacao"
    />

    <DialogMovimentarEstoque
        :dialog.sync="dialogMovimentarEstoque"
        @handleMovimentacao="pegarMovimentacao"
    />
  </v-container>
</template>

<script>
import moment from "moment/moment";
import { mapMutations, mapState } from "vuex";
import DialogAddEstoqueLoja from "@/components/Dialogs/DialogAddEstoqueLoja.vue";
import DialogImportarCSV from "@/components/Dialogs/DialogImportarCSV.vue";
import DialogMovimentarEstoque from "@/components/Dialogs/DialogMovimentarEstoque.vue";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";
import Chips from "primevue/chips";
import InputNumber from "primevue/inputnumber";
import AutoComplete from "primevue/autocomplete";

export default {
  components: {
    AutoComplete,
    InputNumber,
    Chips,
    DialogImportarCSV,
    BotaoBuscarListas,
    DialogAddEstoqueLoja,
    DialogMovimentarEstoque
  },
  data() {
    return {
      tab: 0,
      selectedLocation: null,
      dialogEstoqueLoja: false,
      estoqueParaEdicao: null,
      dialogImportarCSV: false,
      dialogMovimentarEstoque: false,
      etapaTransferencia: 0,
      etapaInventario: 0,
      tiposEstoqueEmpresa: [],
      produtosEstoque: [],
      produtosATransferir: [],
      produtosTransferido: [],
      filtro: [],
      estoques: [],
      estoqueSelecionado: null,
      empresasUsuario: [],
      empresasFiltradas: [],
      empresaSelecionada: null,
      headers: [
        { text: "Código Barras", value: "produto.codigo_barras" },
        { text: "Nome", value: "produto.nome" },
        { text: "Balança", value: "produto.balanca" },
        { text: "Nível mínimo", value: "produto.nivel_minimo" },
        { text: "Disponível", value: "disponivel" },
        { text: "Reposição", value: "reposicao" }
      ],
      headersATransferir: [
        { text: "Código Barras", value: "produto.codigo_barras" },
        { text: "Nome", value: "produto.nome" },
        { text: "Disponível", value: "disponivel" },
        { text: "Reposição", value: "reposicao" }
      ],
      headersTransferido: [
        { text: "Quantidade", value: "quantidade" },
        { text: "Cód", value: "produto.codigo_barras" },
        { text: "Itens", value: "produto.nome" },
        { text: "Remover", value: "remover" }
      ],
      headersListTransferido: [
        { text: "Nome", value: "produto.nome" },
        { text: "Código Barras", value: "produto.codigo_barras" },
        { text: "Quantidade", value: "quantidade" }
      ],
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      showMovimentar: false,
      tipoMovimentacao: null,
      tiposMovimentacoes: [
        { text: "Entrada", value: "ENTRADA" },
        { text: "Saída", value: "SAIDA" },
      ],
      keydownListener: null,
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },
    showColunaEstoques() {

      if(this.tab === 1 && this.etapaTransferencia === 0) {
        return true;
      }

      if(this.tab === 1 && this.etapaTransferencia === 1) {
        return false;
      }

      if(this.tab === 2 && this.etapaInventario === 0) {
        return false;
      }

      if(this.tab === 2 && this.etapaInventario === 1) {
        return true;
      }


      return true;
    }
  },
  watch: {
    async empresaSelecionada(newValue) {
      if (newValue && typeof newValue === "object" && newValue.id) {
        try {
          const estoques = await this.getTiposEstoqueEmpresaID(newValue.id);
          this.estoques = estoques.map(element => ({
            text: element.nome,
            value: element.id
          }));
        } catch (error) {
          console.error("Erro ao buscar estoques:", error);
          this.estoques = [];
        }
      } else {
        this.estoques = [];
      }
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getTiposEstoqueEmpresa() {
      return await this.$axios
        .get(`/estoque/empresa/${this.empresaAtual.id}`)
        .then(async response => {
          this.tiposEstoqueEmpresa = response.data;
          const principal = this.tiposEstoqueEmpresa.find(
            item => item.principal
          );
          if (principal) {
            this.selectedLocation = principal.id;
            this.getProdutosEstoque(principal, false);
          }
        })
        .catch(error => {
          console.log(error);
          this.openDialogMessage({
            type: "error",
            title: "Não foi possível buscar os tipos de estoque",
            text: error.response.data
          });
        });
    },

    async getTiposEstoqueEmpresaID(empresaId) {
      return await this.$axios
        .get(`/estoque/empresa/${empresaId}`)
        .then(async response => {
          return response.data;
        })
        .catch(error => {
          console.log(error);
          this.openDialogMessage({
            type: "error",
            title: "Não foi possível buscar os tipos de estoque",
            text: error.response.data
          });
          // Limpar a lista em caso de erro
          this.estoques = [];
        });
    },

    async getProdutosEstoque(item, filtro) {

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      return await this.$axios
        .get(`/estoque/${item.id}/posicao-atual`, {
          headers: {
            filtro: this.filtro.map(s => s.trim())
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit
          }
        })
        .then(response => {
          this.pagination.total = response.data.count;
          this.produtosEstoque = response.data.data;
          this.produtosATransferir = response.data.data;


          // Executar essa logica se tiver no menu movimentar estoque
          if(this.showMovimentar) {

            if(this.produtosATransferir.length === 1) {
              this.registerKeyListener(this.produtosATransferir[0]);
            }
          }




        })
        .catch(error => {
          console.log(error);
          this.openDialogMessage({
            type: "error",
            title: "Não foi possível buscar os produtos",
            text: error.response.data
          });
        });
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if (newValue) {
        this.filtro.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getProdutosEstoque({ id: this.selectedLocation }, false);
    },

    abrirDialogNovoEstoque() {
      this.estoqueParaEdicao = null;
      this.dialogEstoqueLoja = true;
    },

    editarEstoque(item) {
      this.estoqueParaEdicao = { ...item };
      this.dialogEstoqueLoja = true;
    },

    saveDialogEstoqueLoja() {
      this.getTiposEstoqueEmpresa();
      this.dialogEstoqueLoja = false;
      this.estoqueParaEdicao = null;
    },
    onEstoqueChange(selectedId) {
      const estoque = this.tiposEstoqueEmpresa.find(
        item => item.id === selectedId
      );
      if (estoque) {
        this.getProdutosEstoque(estoque, false);
      }
    },
    adicionarProduto(produto) {
      const existente = this.produtosTransferido.find(
        item => item.produto.codigo_barras === produto.produto.codigo_barras
      );
      if (!existente) {
        this.produtosTransferido.push({
          ...produto,
          quantidade: 1 // Quantidade inicial
        });
      }
    },
    removerProduto(item) {
      this.produtosTransferido = this.produtosTransferido.filter(
        prod => prod.produto.codigo_barras !== item.produto.codigo_barras
      );
    },
    avancarTransferencia() {
      if (this.etapaTransferencia === 0) {
        if (this.produtosTransferido.length === 0) {
          this.openDialogMessage({
            type: "error",
            title: "Erro",
            text: "Adicione pelo menos um produto para avançar."
          });
          return;
        }
      }
      this.etapaTransferencia++;
    },

    avancarInventario() {
      if (this.etapaInventario === 0) {
        if (!this.empresaSelecionada || !this.estoqueSelecionado) {
          this.openDialogMessage({
            type: "error",
            title: "Erro",
            text: "Escolha a empresa e o estoque."
          });
          return;
        }
      }
      this.etapaInventario++;
    },

    voltarTransferencia() {
      if (this.etapaTransferencia > 0) {
        this.etapaTransferencia--;
      }
    },

    voltarInventario() {
      if (this.etapaInventario > 0) {
        this.etapaInventario--;
      }
    },

    async getEmpresas() {
      return this.$axios
        .get(`/usuario/${this.user.id}/empresas`)
        .then(response => {
          this.empresasUsuario = response.data.map(empresa => {
            empresa.telefone = this.$masks.telefone(empresa.telefone);
            empresa.cnpj = this.$masks.cnpj(empresa.cnpj);
            return empresa;
          });

          this.empresasFiltradas = this.empresasUsuario;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Empresas",
            text: error.response.data
          });
        });
    },

    searchEmpresa(event) {
      if (event.query.trim()) {
        const queryText = event.query.toLowerCase();
        this.empresasFiltradas = this.empresasUsuario.filter(item => {
          const razaoSocial = item.razao_social
            ? item.razao_social.toLowerCase()
            : "";
          const cnpj = item.cnpj
            ? this.$masks.cnpj(item.cnpj).toLowerCase()
            : "";
          const nomeFantasia = item.nome_fantasia
            ? item.nome_fantasia.toLowerCase()
            : "";
          const nome = item.nome ? item.nome.toLowerCase() : "";
          const cpf = item.cpf ? this.$masks.cpf(item.cpf).toLowerCase() : "";

          return (
            razaoSocial.includes(queryText) ||
            cnpj.includes(queryText) ||
            nomeFantasia.includes(queryText) ||
            nome.includes(queryText) ||
            cpf.includes(queryText)
          );
        });
      } else {
        this.empresasFiltradas = [...this.empresasUsuario];
      }
    },

    itemTextEmpresa(item) {
      if (item.cpf) {
        return `${item.razao_social} - ${this.$masks.cpf(item.cpf)}`;
      }

      if (item.cnpj) {
        return `${item.razao_social} - ${this.$masks.cnpj(item.cnpj)}`;
      }
    },

    confirmarTransferencia() {
      const payload = {
        origem: {
          id: this.selectedLocation // ID do estoque de origem selecionado
        },
        destino: {
          id: this.estoqueSelecionado // ID do estoque de destino selecionado
        },
        itens: this.produtosTransferido.map(item => ({
          produto: {
            id: item.produto.id // ID do produto transferido
          },
          quantidade: item.quantidade // Quantidade selecionada para o produto
        }))
      };
      this.$axios
        .post("/estoque/transferencia", payload)
        .then(response => {
          this.openDialogMessage({
            type: "success",
            title: "Transferência realizada",
            text: "Os produtos foram transferidos com sucesso."
          });
          // Resetar variáveis ou etapas após a transferência
          this.etapaTransferencia = 0;
          this.produtosTransferido = [];
          this.empresaSelecionada = null;
          this.estoqueSelecionado = null;
        })
        .catch(error => {
          console.error(
            "Erro ao realizar a transferência:",
            error.response?.data || error.message
          );
          this.openDialogMessage({
            type: "error",
            title: "Erro ao realizar a transferência",
            text: error.response?.data?.message || "Ocorreu um erro inesperado."
          });
        });
    },

    confirmarMovimentar() {
      const payload = {
        tipo: this.tipoMovimentacao,
        estoque: {
          id: this.selectedLocation
        },
        movimentos: this.produtosTransferido.map(item => ({
          produto: {
            id: item.produto.id
          },
          quantidade: item.quantidade
        }))
      };

      this.$axios
          .post("/estoque/lote-movimento", payload)
          .then(response => {
            this.openDialogMessage({
              type: "success",
              title: "Movimentação realizada",
              text: "Os produtos foram movimentados com sucesso."
            });
            this.voltarMovimentar();
          })
          .catch(error => {
            console.error(
                "Erro ao realizar a movimentação:",
                error.response?.data || error.message
            );
            this.openDialogMessage({
              type: "error",
              title: "Erro ao realizar a movimentação",
              text: error.response?.data?.message || "Ocorreu um erro inesperado."
            });
          });

    },

    confirmarInventario() {
      const payload = {
        estoque: {
          id: this.estoqueSelecionado
        },
        itens: this.produtosTransferido.map(item => ({
          produto: {
            id: item.produto.id
          },
          quantidade: item.quantidade
        }))
      };

      console.log("Inventario-------->", payload)

      // this.$axios
      //     .post("/estoque/lote-movimento", payload)
      //     .then(response => {
      //       this.openDialogMessage({
      //         type: "success",
      //         title: "Movimentação realizada",
      //         text: "Os produtos foram movimentados com sucesso."
      //       });
      //       // Resetar variáveis ou etapas após a transferência
      //       this.etapaMovimentar = 0;
      //       this.produtosTransferido = [];
      //       this.empresaSelecionada = null;
      //       this.estoqueSelecionado = null;
      //       this.tipoMovimentacao = null;
      //     })
      //     .catch(error => {
      //       console.error(
      //           "Erro ao realizar a movimentação:",
      //           error.response?.data || error.message
      //       );
      //       this.openDialogMessage({
      //         type: "error",
      //         title: "Erro ao realizar a movimentação",
      //         text: error.response?.data?.message || "Ocorreu um erro inesperado."
      //       });
      //     });

    },

    abrirDialogImportarCSV() {
      this.dialogImportarCSV = true;
    },
    async processarImportacao(dadosCSV) {
      for (const item of dadosCSV) {
        try {
          const response = await this.$axios.get(
            `/estoque/${this.selectedLocation}/posicao-atual`,
            {
              headers: { filtro: [item.codigo_barras] }
            }
          );

          const produto = response.data.data.find(
            (p) => p.produto.codigo_barras === item.codigo_barras
          );

          if (produto) {
            const produtoExistente = this.produtosTransferido.find(
              (prod) =>
                prod.produto.codigo_barras === produto.produto.codigo_barras
            );

            if (produtoExistente) {
              produtoExistente.quantidade += item.quantidade;
            } else {
              this.produtosTransferido.push({
                ...produto,
                quantidade: item.quantidade
              });
            }
          } else {
            this.openDialogMessage({
              type: "warning",
              title: "Produto não encontrado",
              text: `O produto com código de barras ${item.codigo_barras} não foi encontrado no estoque.`
            });
          }
        } catch (error) {
          console.error("Erro ao buscar produto:", error);
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar produto",
            text: `Não foi possível buscar o produto com código ${item.codigo_barras}.`
          });
        }
      }

      this.openDialogMessage({
        type: "success",
        title: "Importação concluída",
        text: "Os produtos foram importados com sucesso."
      });
    },
    mostrarErroImportacao(mensagemErro) {
      this.openDialogMessage({
        type: "error",
        title: "Erro ao importar CSV",
        text: mensagemErro
      });
    },

    handleChangeTab() {
      this.empresaSelecionada = null;
      this.estoqueSelecionado = null;
      this.produtosTransferido = [];
      this.filtro = [];
      this.showMovimentar = false;
    },

    abrirDialogMovimentar() {
      this.dialogMovimentarEstoque = true;
    },

    voltarMovimentar() {
      this.tipoMovimentacao = null;
      this.showMovimentar = false;
      this.produtosTransferido = [];
      this.filtro = [];
      this.getProdutosEstoque({ id: this.selectedLocation }, true);
    },

    pegarMovimentacao(value) {
      this.tipoMovimentacao = value;
      this.showMovimentar = true;
      this.dialogMovimentarEstoque = false;
      this.produtosTransferido = [];
      this.filtro = [];
    },

    registerKeyListener(item) {
      const adicionadoProduto = this.produtosTransferido.filter(obj => obj.produto.id === item.produto.id).length;

      if(adicionadoProduto > 0) {
        this.openDialogMessage({
          type: "warning",
          title: "Atenção",
          text: "Este produto já foi adicionado"
        });

        return;
      }

      this.produtosTransferido.push({
        ...item,
        quantidade: 1
      });

    },


  },
  mounted() {
    this.getTiposEstoqueEmpresa();
    this.getEmpresas();
  }
};
</script>

<style scoped>
.div-quantidade {
  width: 8rem !important;
}

.autocomplete-input {
  width: 100%;
  height: 60px;
}

.div-clearable {
  margin-left: -50px;
  margin-top: 18px;
  cursor: pointer;
}

.billing-container {
  padding: 10px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 5px;
  background: #fff;
}

.billing-title {
  background: #fff;
  padding: 0 10px;
  position: absolute;
  top: -10px;
  left: 20px;
}

.v-file-input {
  margin-top: 10px;
}

.estoque-selecionado {
  border: 1px solid black;
}
</style>
