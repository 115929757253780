<template>
  <v-container fluid>
    <form @submit.prevent="getRemessas(true)">
      <v-row>
        <div class="espacado">
            <Calendar v-model="dataIntervalo" selectionMode="range"
                      @date-select="getRemessas(true)" :showIcon="true"
                      dateFormat="dd/mm/yy" />
        </div>
        <div class="espacado">
          <Dropdown v-model="tipo" :options="tipos" @change="getRemessas(true)"
                      optionLabel="name" optionValue="code"
                      placeholder="Filtro por tipo" display="chip"/>

        </div>
        <div class="espacado">
          <Chips ref="chipcomponente" v-model="filtros" separator=","  @add="getRemessas(true)" @remove="getRemessas(true)"
                  placeholder="Filtro por nome, telefone, email"/>
        </div>
        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>

      </v-row>

    </form>
    <v-row style="justify-content: end;">

      <v-col cols="3">
        <v-file-input label="Arquivo CNAB 240" dense
                      validate-on-blur
                      truncate-length="20"
                      v-model="arquivo">
        </v-file-input>
      </v-col>
      <v-col cols="1">
        <v-btn dark  color="primary" @click="upload(arquivo)">
          Enviar
        </v-btn>
      </v-col>

    </v-row>

    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <DataTable :value="remessas"
                     rowGroupMode="rowspan" groupRowsBy="id"
                     breakpoint="960px" scrollHeight="flex-row">

            <Column field="id" header="Lote">
              <template #body="slot">
                <div style="width: 10rem;">
                  <h5>{{ TIPO_REMESSA[slot.data.tipo].texto }}</h5>
                  <h4>{{ slot.data.data_criacao | formatDate({ dateStyle: "short", timeStyle: "short" }) }} </h4>
                  <h4>{{ slot.data.numero_arquivo }}</h4>
                  <p>Sucesso  {{ slot.data.sucesso }} Falhas  {{ slot.data.falhas }} </p>
                </div>
              </template>
            </Column>

            <Column field="remessa" header="Remessa" >
              <template #body="slot">
                {{ slot.data.remessa }}
              </template>
            </Column>

            <Column field="status" header="Status">
              <template #body="slot">
                {{ slot.data.status }}
              </template>
            </Column>

            <Column field="data_vencimento" header="vencimento">
              <template #body="slot">
                {{ slot.data.data_vencimento | formatDate({ dateStyle: "short" }) }}
              </template>
            </Column>

            <Column field="valor" header="Valor">
              <template #body="slot">
                R$ {{ slot.data.valor  | formatMoney({ minimumFractionDigits: 2 }) }}
              </template>
            </Column>

            <Column field="cliente" header="Cliente">
              <template #body="slot">
                <div style="max-width: 22rem;" class="texto">{{ slot.data.cliente.nome }}</div>
              </template>
            </Column>

            <Column field="info" header="Informação" >
              <template #body="slot">
                <v-tooltip bottom v-if="slot.data.info">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark  v-bind="attrs" v-on="on" >
                      mdi-alert-circle-outline
                    </v-icon>
                  </template>
                  <span>{{slot.data.info}}</span>
                </v-tooltip>
              </template>
            </Column>

          </DataTable>
        </v-skeleton-loader>
        <v-pagination  v-model="pagination.page" :length="totalPaginas" @input="next"></v-pagination>

      </v-col>
    </v-row>

  </v-container>
</template>

<script>
import Chips from 'primevue/chips';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import { mapState, mapMutations } from "vuex";
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import CryptoJS from "crypto-js";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Remessas",
  components:{
    Dropdown, Chips,
    DataTable,
    Column,
    Calendar,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      filtros: this.$store.state.filtrosRemessas.filtros,
      tipo: this.$store.state.filtrosRemessas.tipo,
      dataIntervalo: this.$store.state.filtrosRemessas.dataIntervalo,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      tipos:[
         {name:'Arquivo Remessa',code:'REMESSA'},
         {name:'Arquivo Retorno',code:'RETORNO'}
      ],
      remessas: [],
      arquivo: null,
      pagination: {
        page: 1,
        total: 0,
        limit: 9,
        first:0
      },
    };
  },
  mounted() {
    this.getRemessas();
  },
  computed: {
    ...mapState(["empresaAtual","user"]),
    totalPaginas(){
      return Math.ceil(this.pagination.total / this.pagination.limit);
    },

  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
     totalSucesso(cnabs){
      return cnabs.filter(obj => obj.status == 'EMITIDO').length;
    },
    totalFalha(cnabs){
      return cnabs.filter(obj => obj.status == 'FALHA').length;
    },
    async upload(arquivo){
      if(!arquivo) return;
      console.log('Arquivo', arquivo.name)

      const boundaryTest = CryptoJS.SHA1(arquivo.name).toString();
      const formData = new FormData();
      formData.append("file", arquivo);

     await this.$axios.post(`/cnab/empresa/${this.empresaAtual.id}/remessa`, formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "file-token": boundaryTest,
            },
          }
        )
        .then(() => {
          this.openDialogMessage({
            type: "success",
            title: "Envio Remessa",
            text: "Remessa enviada com sucesso!",
          });
        })
        .catch((error) => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Envio remessa",
            text: error.response.data,
          });
        }).finally(()=>{
          this.arquivo = null;
          this.getRemessas();
        });


    },
    getRemessas(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosRemessas", {
        filtros: this.filtros,
        tipo: this.tipo,
        dataIntervalo: this.dataIntervalo
      });

      if(this.dataIntervalo[0]){
        this.dataInicio = this.dataIntervalo[0].toISOString().substr(0, 10);
      }

      if(this.dataIntervalo[1]){
        this.dataFim = this.dataIntervalo[1].toISOString().substr(0, 10);
      }

      if(!this.dataIntervalo[0] || !this.dataIntervalo[1]){
        return;
      }

      if(!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios.get(`/cnab/empresa/${this.empresaAtual.id}/remessas`,
        {
          headers:{
            "filtros": JSON.stringify(this.filtros)
          },
          params: {
            "data-inicio": this.dataInicio,
            "data-fim": this.dataFim,
            "first": this.pagination.first,
            "limit": this.pagination.limit,
            "tipo": this.tipo,
            "ordem": 'DESC'
          }
        }).then((response) => {

          this.remessas = [];

          response.data.remessas.forEach(obj=>{

            obj.cnabs.forEach(cnab => {
                const linha = {
                  id: obj.id,
                  data_criacao: obj.data_criacao,
                  numero_arquivo : obj.numero_arquivo,
                  empresa: obj.empresa,
                  tipo: obj.tipo,
                  cliente: cnab.cliente,
                  valor: cnab.valor,
                  remessa: cnab.id,
                  data_vencimento: cnab.data_vencimento,
                  status: cnab.status,
                  info: cnab.info,
                  sucesso: this.totalSucesso(obj.cnabs),
                  falhas : this.totalFalha(obj.cnabs)
                }

                this.remessas.push(linha)
            });

          });
          this.remessas.sort((a,b)=>{
            const dataA = new Date(a.data_criacao)
            const dataB = new Date(b.data_criacao)
            if(dataA.getTime() < dataB.getTime()){
              return 1
            }else if(dataA.getTime() > dataB.getTime()){
              return -1
            }
            return 0;
          })

          this.pagination.total = response.data.count;
        }).catch((error) => {
          this.remessas = [];
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar remessas",
            text: error.response.data,
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    next(page){
      this.pagination.page = page
      this.pagination.first = ((page-1) * this.pagination.limit);
      this.getRemessas();
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

  },
};
</script>

<style scoped>
.texto {
  white-space: nowrap; /* Evita que o texto quebre linha */
  overflow: hidden; /* Esconde o texto que ultrapassa a largura máxima */
  text-overflow: ellipsis; /* Exibe "..." quando o texto é cortado */
}

.espacado{
  margin: 0.5rem;
}
.p-multiselect{
  min-width: 15rem;
  width: 100%;
}
.p-chips {
  min-width: 25rem;
  display: flex;
  flex-direction: column;
}


@media (max-width: 900px) {
  .col,.col-1, .col-3, .col-4 {
    flex: none;
    max-width: 20rem;
  }
}
</style>
