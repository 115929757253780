import { RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import Bank from "@/views/Bank/Bank.vue";
import Extrato from "@/views/Bank/Extrato.vue"

const empresaRoutes: RouteConfig = {
  path: "/relatorios/bank",
  component: GenericComponent,

  children: [
    {
      path: "/",
      component: Bank,

      meta: {
        breadCrumb: [
          {
            text: "Bank"
          }
        ]
      },
      children:[
        {
          path:"/",
          redirect:"extrato"
        },
        {
          path:"extrato",
          component: Extrato,
          name:"Extrato",

          meta:{
            breadCrumb: [
              {
                text: "Relatórios"
              },
              {
                text:"Extrato conta"
              }
            ]
          }
        }
      ]
    },

  ]
};

export default empresaRoutes;
