<template>
  <v-container fluid>
    <v-row>
      <div class="espacado">
        <Chips
          ref="chipcomponente"
          v-model="filtros.categoria"
          @add="searchCategorias"
          @remove="searchCategorias"
          placeholder="Filtro por nome"
        />
      </div>
      <div class="espacado">
        <BotaoBuscarListas @buscar="buscar" />
      </div>
      <v-col class="text-end">
        <v-tooltip bottom v-if="user.permissoes.adm_servico">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              dark
              small
              color="primary"
              @click="adicionar()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              :fixed-header="true"
              hide-default-footer
              :headers="headers"
              :items="categoriasNaPagina"
              :footer-props="{
            itemsPerPageOptions: LINHAS_POR_PAGINA
          }"
          >
            <template v-slot:[`item.foto_url`]="{ item }">
              <img
                width="60px"
                height="60px"
                :title="item.nome"
                style="margin: 1rem;object-fit: contain"
                :src="item.foto_url"
                :key="item.foto_url"
                v-if="item.foto_url"
                :alt="item.nome"
              />
              <img
                v-else
                width="60px"
                height="60px"
                style="margin: 1rem;object-fit: contain"
                :src="require(`@/assets/img/sem-imagem.png`)"
                :alt="item.nome"
              />
            </template>

            <template v-slot:[`item.destaque`]="{ item }">
              <v-rating
                background-color="primary"
                readonly
                length="1"
                :value="item.destaque === true ? 1 : 0"
              ></v-rating>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-pagination
      class="mt-5"
      v-model="pagination.page"
      :length="totalPaginas"
      @input="setPagina"
      :total-visible="10"
    ></v-pagination>

    <Dialog
      header="Categoria"
      :visible="showDialog"
      :closable="false"
      modal
      :contentStyle="{ width: '500px' }"
    >
      <form
          action=""
          id="form"
          name="formEmpresa"
          class="mt-3"
          @submit.prevent="salvar()"
      >
        <v-row style="display: none" class="mx-auto">
          <v-col class="text-end">
            <v-btn dark color="primary" type="submit" ref="btnSubmit"></v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="4">
            <img
                width="150px"
                height="150px"
                title="Icone da categoria"
                :src="categoriaSelecionada.foto_url"
                v-if="categoriaSelecionada.foto_url"
            />
          </v-col>
          <v-col>
            <v-row>
              <v-col>
                <v-file-input
                    label="Carregar imagem"
                    accept="image/jpeg"
                    truncate-length="20"
                    v-model="imagem"
                >
                </v-file-input>
              </v-col>
            </v-row>

            <v-row>
              <v-col>
              <span class="p-float-label">
                <InputText
                    required
                    id="nome"
                    type="text"
                    v-model="categoriaSelecionada.nome"
                />
                <label for="nome">Nome da categoria</label>
              </span>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="6">
              <span class="p-float-label">
                <InputText
                    id="prioridade"
                    type="number"
                    v-model="categoriaSelecionada.prioridade"
                />
                <label for="prioridade">Prioridade</label>
              </span>
              </v-col>
              <v-col cols="6">
              <span class="p-float-label">
                <v-switch
                    id="destaque"
                    color="green"
                    v-model="categoriaSelecionada.destaque"
                ></v-switch>
                <label class="mt-n4 ml-10" for="destaque">Destaque</label>
              </span>
              </v-col>
            </v-row>

          </v-col>
        </v-row>

      </form>

      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="fecharDialog"
        />
        <Button
          label="Salvar"
          icon="pi pi-check"
          class="primary"
          autofocus
          @click="submit"
        />
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import CryptoJS from "crypto-js";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Chips from "primevue/chips";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Categorias",
  components: {
    Dialog,
    Button,
    InputText,
    Chips,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      categorias: [],
      showDialog: false,
      categoriaSelecionada: {},
      imagem: null,
      headers: [
        {
          text: "Icone",
          align: "center",
          sortable: false,
          value: "foto_url",
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "nome",
        },
        {
          text: "Destaque",
          align: "start",
          sortable: false,
          value: "destaque",
        },
        {
          text: "Prioridade",
          align: "start",
          sortable: false,
          value: "prioridade",
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          value: "acoes"
        }
      ],
      filtros: {
        categoria: []
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 5,
        first: 0
      },
      categoriasNaPagina: []
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },

  mounted() {
    this.getCategorias();
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    adicionar() {
      this.categoriaSelecionada = {
        empresa_id: this.empresaAtual.id
      };
      this, (this.imagem = null);
      this.showDialog = true;
    },
    editar(categoria) {
      this.categoriaSelecionada = categoria;
      this.imagem = null;
      this.showDialog = true;
    },
    fecharDialog() {
      this.showDialog = false;
    },
    submit() {
      this.$refs.btnSubmit.$el.click();
    },
    async searchCategorias() {

      if (this.filtros.categoria.length > 1) {
        this.filtros.categoria = [this.filtros.categoria[1]];
         await this.getCategorias();
      }

      const search = this.filtros.categoria[0]?.toUpperCase() || "";

      if (search) {
        this.categorias = this.categorias.filter(item => item.nome.toUpperCase().includes(search));
        this.pagination.total = this.categorias?.length;
        this.setPagina(this.pagination.page);
      } else {
        await this.getCategorias();
      }

    },
    salvar() {
      const id = this.categoriaSelecionada?.id;
      this.$axios
        .post("categoria", this.categoriaSelecionada)
        .then(response => {
          this.categoriaSelecionada = response.data;
          this.upload();
          this.fecharDialog();
          this.getCategorias();

          this.$toast.add({
            severity: "success",
            summary: id ? `Categoria atualizada com sucesso.` : `Categoria cadastrada com sucesso.`,
            life: 2000
          });

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cadastrar",
            text: error.response.data
          });
        });
    },
    async upload() {
      if (!this.imagem) {
        return;
      }

      const boundaryTest = CryptoJS.SHA1(this.imagem.name).toString();
      const formData = new FormData();
      formData.append("file", this.imagem);

      await this.$axios
        .post(
          `/categoria/${this.categoriaSelecionada.id}/upload-imagem`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "file-token": boundaryTest
            }
          }
        )
        .then(response => {
          this.categoriaSelecionada = response.data;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Envio da imagen",
            text: error.response.data
          });
        });
    },
    async getCategorias() {
      this.loadingSkeleton = true;

      if (!this.empresaAtual || !this.empresaAtual.id) return;
      this.categorias = [];
      await this.$axios
        .get(`/categoria/empresa/${this.empresaAtual.id}`, {
          headers: {
            filtros: JSON.stringify({
              categoria: this.filtros.categoria.map(p => p.trim())
            })
          }
        })
        .then(async response => {
          this.categorias = response.data;
          this.pagination.total = this.categorias?.length;
          this.setPagina(this.pagination.page);
        })
        .catch(error => {
          this.categorias = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha o carregar categorias",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },

    setPagina(page) {
      const startIndex = (page - 1) * this.pagination.limit;
      const endIndex = startIndex + this.pagination.limit;
      this.categoriasNaPagina = this.categorias.slice(startIndex, endIndex); // Atualiza as categorias da página atual
    },

  buscar() {
    const chip = this.$refs.chipcomponente;
    const newValue = chip.$refs.input.value;

    if(newValue) {
      this.filtros.categoria.push(newValue);
      chip.$refs.input.value = "";
      chip.$emit("add", newValue);
    } else {
      chip.$emit("add");
    }
  },

  }
};
</script>

<style></style>
