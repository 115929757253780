<template>
  <div class="mt-2">
    <v-row>
      <v-col class="d-flex justify-start">
        <Calendar
            v-model="dataIntervalo"
            selectionMode="range"
            @date-select="getDados"
            :showIcon="true"
            dateFormat="dd/mm/yy"
        />
        <BotaoBuscarListas class="ml-3" @buscar="getDados" />
      </v-col>

      <v-col>
        <div class="text-end">
          <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <!-- SKELETON -->
    <v-row v-if="loadingSkeleton">
      <v-col cols="12">
        <v-skeleton-loader
            :loading="true"
            type="image"
        ></v-skeleton-loader>
      </v-col>
    </v-row>

    <v-row v-else class="mx-auto mt-5" dense>
      <v-col cols="12">
        <v-data-table hide-default-header hide-default-footer disable-pagination ref="table">

          <template v-slot:[`header`]>
            <thead>
            <tr>
              <th style="background-color: #eee" class="text-center border-top border-right border-left">Transação</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Status</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Criação</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Tipo</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Valor Transação</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Parcelas</th>
              <th style="background-color: #eee" class="text-center border-top border-right">CNPJ Recebedor</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Recebedor</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Compensação</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Compensação Original</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Valor</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Tx Adm</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Tx Ant</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Líquido</th>
              <th style="background-color: #eee" class="text-center border-top border-right">Parcela</th>
            </tr>
            </thead>
          </template>

          <template v-slot:[`body`]>
            <tr v-for="(dado, index) of dados" :key="index">
              <td class="text-center border-bottom border-right border-left">
                {{ dado.transacao?.id }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.transacao?.status }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.transacao?.data_criacao | formatDate({ day: '2-digit', month: '2-digit', year: '2-digit' }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.transacao?.tipo }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.transacao?.valor| formatMoney({minimumFractionDigits: 2 }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.transacao?.parcelas }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.recebedor.cnpj }}
              </td>
              <td class="text-center border-bottom border-right truncate">
                {{ dado.recebedor.nome_fantasia }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.data_compensacao | formatDate({ day: '2-digit', month: '2-digit', year: '2-digit' }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.data_compensacao_original | formatDate({ day: '2-digit', month: '2-digit', year: '2-digit' }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.valor | formatMoney({minimumFractionDigits: 2 }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.valor_taxa_administracao | formatMoney({minimumFractionDigits: 2 }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.valor_taxa_antecipacao | formatMoney({minimumFractionDigits: 2 }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.valor_liquido | formatMoney({minimumFractionDigits: 2 }) }}
              </td>
              <td class="text-center border-bottom border-right">
                {{ dado.parcela }}/{{ dado.transacao?.parcelas }}
              </td>
            </tr>
          </template>

        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import moment from "moment";
import Calendar from "primevue/calendar";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "PagamentoAnalitico",
  components: {
    BotaoBuscarListas,
    Calendar

  },
  data() {
    return {
      loadingSkeleton: false,
      dataIntervalo: [new Date(), new Date()],
      menuDataInicio: false,
      menuDataFim: false,
      dataInicio: new Date().toISOString().substr(0, 10),
      dataFim: new Date().toISOString().substr(0, 10),
      dados: [],
      grafico: {
        labels: [],
        datasets: [],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false
      }
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getDados() {
      //this.loadingSkeleton = true;

      if (this.dataIntervalo[0]) {
        this.dataInicio = moment(this.dataIntervalo[0]).format("YYYY-MM-DD");
      }

      if (this.dataIntervalo[1]) {
        this.dataFim = moment(this.dataIntervalo[1]).format("YYYY-MM-DD");
      }

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
          .get(
              `/relatorio/pagamento/empresa/${this.empresaAtual.id}/analitico`,
              {
                params: {
                  "data-inicio": this.dataInicio,
                  "data-fim": this.dataFim
                }
              }
          )
          .then(response => {
            this.dados = response.data.data;
            this.loadingSkeleton = false;
          })
        .catch(error => {
          this.dados = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha na consulta",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    exportar() {
      const copy = this.dados.map(item => {
        const obj = {};
        obj.transacao_id = item.transacao?.id;
        obj.data_criacao = item.transacao?.data_criacao.split('T')[0];
        obj.origem = item.transacao?.origem;
        obj.parcelas = item.transacao?.parcelas;
        obj.pdv_adquirente = item.transacao?.pdv_adquirente;
        obj.status = item.transacao?.status;
        obj.tipo = item.transacao?.tipo;
        obj.arranjo = item.transacao?.arranjo;
        obj.transacao_valor = this.parseFloatToMoney(item.transacao?.valor);
        obj.transacao_mdr = this.parseFloatToMoney(item.transacao?.valor_taxa_administracao);
        obj.emissor_id = item.transacao?.empresa?.id;
        obj.emissor_cnpj = item.transacao?.empresa?.cnpj;
        obj.emissor_razao_social = item.transacao?.empresa?.razao_social;
        obj.cliente_id = item.transacao?.cliente?.id;
        obj.cliente_tipo = item.transacao?.cliente?.tipo;
        obj.cliente_nome = item.transacao?.cliente?.nome;
        obj.cliente_documento = item.transacao?.cliente?.documento;
        obj.cliente_telefone_celular = item.transacao?.cliente?.telefone_celular;
        obj.cliente_email = item.transacao?.cliente?.email;
        obj.recebedor_id = item.recebedor?.id;
        obj.recebedor_cnpj = item.recebedor?.cnpj;
        obj.recebedor_razao_social = item.recebedor?.razao_social;
        obj.recebedor_nome_fantasia = item.recebedor?.nome_fantasia;
        obj.id_parcela_recebivel = item.id;
        obj.status_parcela_recebivel = item.status;
        obj.data_compensacao = item.data_compensacao.split('T')[0];
        obj.data_compensacao_original = item.data_compensacao_original.split('T')[0];
        obj.valor_parcela_recebivel = this.parseFloatToMoney(item.valor);
        obj.valor_taxa_administracao_parcela_recebivel = this.parseFloatToMoney(item.valor_taxa_administracao);
        obj.valor_taxa_antecipacao_parcela_recebivel = this.parseFloatToMoney(item.valor_taxa_antecipacao);
        obj.valor_liquido_parcela_recebivel = this.parseFloatToMoney(item.valor_liquido);
        obj.parcela = item.parcela;

        return this.replaceUndefinedWithEmptyString(obj);
      });

      this.toCSVeDownload("pagamento-analitico.csv", copy);
    },

    replaceUndefinedWithEmptyString(obj) {
      const newObj = {};
      Object.keys(obj).forEach(key => {
        newObj[key] = obj[key] === undefined ? "" : obj[key];
      });
      return newObj;
    },

  },
  mounted() {
    this.getDados();
  }
};
</script>

<style scoped>
.border-right {
  border-right: 1px solid rgba(0, 0, 0, 0.2);
}
.border-left {
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.border-full {
  border: 1px solid rgba(0, 0, 0, 0.2);
}
.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 120px;
}


</style>
