import { RouteConfig } from "vue-router";
import GenericComponent from "@/components/GenericComponent.vue";
import MesaAntecipacao from "@/views/MesaAntecipacao/MesaAntecipacao.vue";

const mesaAntecipacaoRoutes: RouteConfig = {
  path: "/mesa-antecipacao",
  component: GenericComponent,

  children: [
    {
      path: "/",
      name: "mesa-antecipacao",
      component: MesaAntecipacao,

      meta: {
        breadCrumb: [
          {
            text: "Mesa de antecipação"
          }
        ]
      }
    }
  ]
};
export default mesaAntecipacaoRoutes;
