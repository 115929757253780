<template>
  <div v-if="user && user.permissoes.extrato_conta">
    <!-- SKELETON -->
    <v-container v-if="loadingSkeleton" fluid>
      <v-row class="grey lighten-5 px-16 mt-10">
        <v-col cols="12">
          <v-skeleton-loader
              :loading="true"
              type="image"
              height="70"
              class="mb-3"
          ></v-skeleton-loader>
          <v-skeleton-loader
              :loading="true"
              type="heading"
          ></v-skeleton-loader>
          <v-skeleton-loader
              class="mt-3"
              :loading="true"
              type="image@3"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </v-container>

    <v-container v-else>
      <div>
          <v-row>
            <v-col cols="7">
              <v-select :items="contas" :rules="rules.select"
                        v-model="conta" hide-details
                        item-value="conta.id" @change="getSaldo">

                <template v-slot:item="{ item }">
                  <div class="dados-bancario">
                    <div class="logo-banco">
                      <img width="30px"
                           height="30px"
                           :title="item.banco.codigo_febraban" :src="logoBanco(item)"/>
                    </div>
                    <div class="info-banco text-caption d-flex flex-column">
                      <span> {{ item.banco.codigo_febraban }} - {{ item.banco.nome }} </span>
                      <span>Agência {{ item.agencia }} {{item.digito_agencia}}</span>
                      <span>Conta {{ item.numero_conta }} {{ item.digito_numero_conta }}</span>
                    </div>
                  </div>
                </template>

                <template v-slot:selection="{ item }">
                  <div class="dados-bancario">
                    <div class="logo-banco">
                      <img width="30px"
                           height="30px"
                           :title="item.banco.codigo_febraban" :src="logoBanco(item)"/>
                    </div>
                    <div class="info-banco text-caption d-flex flex-column">
                      <span> {{ item.banco.codigo_febraban }} - {{ item.banco.nome }} </span>
                      <span>AG {{ item.agencia }} {{item.digito_agencia}} CC{{ item.numero_conta }} {{ item.digito_numero_conta }}</span>
                    </div>
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col>
              <div style="text-align: end;">
                  <span style="color:rgba(0,0,0,.6)" class="text-caption ma-1">
                    Saldo
                  </span>
                  <div>
                    <span class="ml-5 caption font-weight-bold green--text">R$</span>
                    <span class="text-h3 green--text font-weight-bold">{{ saldo | formatMoney({ minimumFractionDigits: 2 }) }}</span>
                  </div>
                 
                </div>
                <v-divider></v-divider>
             </v-col>
          </v-row>
      </div>
      <div>
        <v-row>
          <v-col>
             <Extrato :contaCorrente="conta.id" />
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Extrato from "@/views/Bank/Extrato.vue"

function initialState() {
  return {
    loadingSkeleton: false,
    contas: [],
    conta: {id:null},
    saldo: 0,
    intervaloCustom: 0,
    itemMenu: 0,
    rules: {
      select: [v => !!v || "Escolha uma Conta Corrente"]
    }
  };
}

export default {
  name: "Bank",
  props: {
    mode: String
  },
  components:{
    Extrato
  },
  computed: {
    ...mapState(["user","empresaAtual"])
  },
  data() {
    return initialState();
  },
  mounted() {
    if(!this.user || !this.user.permissoes.extrato_conta){
      this.$store.commit("logout", false);
      return;
    }
    this.getContasCorrentes();
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getContasCorrentes() {
      this.loadingSkeleton = true;

      this.$axios
        .get(`/empresa/${this.empresaAtual.id}/conta-corrente`)
        .then(async response => {
          this.contas = response.data;
          this.conta = this.contas.filter(c => c.principal)[0];
          if (this.conta) {
            await this.getSaldo();
          } else {
            this.loadingSkeleton = false;
          }
        });
    },

    async getSaldo() {
      await this.$axios
        .get(`/banco/conta/${this.conta.id}/saldo`)
        .then(response => {
          this.saldo = response.data.saldo;
          this.loadingSkeleton = false;
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar Saldo",
            text: error.response.data
          });
        });
    },
    logoBanco(conta){
      return `${process.env.VUE_APP_ROOT_API}/public/banco/${conta.banco.codigo_febraban}/logo`
    }
  }
};
</script>

<style scoped>
.item-list:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  cursor: pointer;
}

.card-saldo {
  border: none !important;
  border-bottom: 1px solid #8f8f8f !important;;
  border-radius: 0 !important;
  margin-top: 1px !important;
}

@media (max-width: 900px) {
  .col,
  .col-4 {
    flex: none;
    max-width: 25rem;
  }
}
.dados-bancario{
  flex: 25;
  display: flex;
  justify-content: flex-start;

}
.logo-banco{
  margin: 0.5rem;
}
.info-banco{
  margin: 0.5rem;
}


</style>
