import { render, staticRenderFns } from "./CardRecebiveisBruto.vue?vue&type=template&id=e806dd86&scoped=true"
import script from "./CardRecebiveisBruto.vue?vue&type=script&lang=js"
export * from "./CardRecebiveisBruto.vue?vue&type=script&lang=js"
import style0 from "./CardRecebiveisBruto.vue?vue&type=style&index=0&id=e806dd86&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e806dd86",
  null
  
)

export default component.exports