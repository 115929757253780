import { render, staticRenderFns } from "./DetalhesEmpresa.vue?vue&type=template&id=f14ef7dc&scoped=true"
import script from "./DetalhesEmpresa.vue?vue&type=script&lang=js"
export * from "./DetalhesEmpresa.vue?vue&type=script&lang=js"
import style0 from "./DetalhesEmpresa.vue?vue&type=style&index=0&id=f14ef7dc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f14ef7dc",
  null
  
)

export default component.exports