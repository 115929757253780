<template>
  <v-container fluid>
    <v-row>
      <div class="espacado">
        <Chips
          ref="chipcomponente"
          v-model="filtros.setor"
          @add="searchSetores"
          @remove="searchSetores"
          placeholder="Filtro por nome"
        />
      </div>
      <div class="espacado">
        <BotaoBuscarListas @buscar="buscar" />
      </div>
      <v-col class="text-end">
        <v-tooltip bottom v-if="user.permissoes.adm_servico">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="primary"
                @click="adicionar()"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
            :fixed-header="true"
            hide-default-footer
            :headers="headers"
            :items="setoresNaPagina"
            :footer-props="{ itemsPerPageOptions: LINHAS_POR_PAGINA }"
          >

            <template v-slot:[`item.imprimir`]="{ item }">
              <span v-if="item.imprimir">Sim</span>
              <span v-else>Não</span>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="editar(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>

    <v-pagination
        class="mt-5"
        v-model="pagination.page"
        :length="totalPaginas"
        @input="setPagina"
        :total-visible="10"
    ></v-pagination>

    <Dialog
        header="Setor"
        :visible="showDialog"
        :closable="false"
        modal
        :contentStyle="{ width: '500px' }"
    >
      <form
          action=""
          id="form"
          name="formSetor"
          class="mt-3"
          @submit.prevent="salvar()"
      >
        <v-row style="display: none" class="mx-auto">
          <v-col class="text-end">
            <v-btn dark color="primary" type="submit" ref="btnSubmit"></v-btn>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <span class="p-float-label">
              <InputText
                required
                id="nome"
                type="text"
                v-model="setorSelecionado.nome"
              />
              <label for="nome">Nome do setor</label>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <span class="p-float-label">
              <InputText
                required
                id="impressora_ip"
                type="text"
                v-model="setorSelecionado.impressora_ip"
              />
              <label for="impressora_ip">IP impressora</label>
            </span>
          </v-col>
          <v-col cols="6">
            <span class="p-float-label">
              <InputText
                required
                id="impressora_porta"
                type="text"
                v-model="setorSelecionado.impressora_porta"
              />
              <label for="impressora_porta">Porta impressora</label>
            </span>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="6">
            <span class="p-float-label">
              <InputText
                required
                id="vias"
                type="number"
                v-model="setorSelecionado.vias"
              />
              <label for="vias">Vias</label>
            </span>
          </v-col>
          <v-col cols="6">
            <span class="p-float-label">
              <v-switch
                id="imprimir"
                color="green"
                v-model="setorSelecionado.imprimir"
              ></v-switch>
              <label class="mt-n4 ml-10" for="imprimir">Imprimir</label>
            </span>
          </v-col>
        </v-row>

      </form>

      <template #footer>
        <Button
          label="Cancelar"
          icon="pi pi-times"
          class="p-button-text"
          @click="fecharDialog"
        />
        <Button
          label="Salvar"
          icon="pi pi-check"
          class="primary"
          autofocus
          @click="submit"
        />
      </template>
    </Dialog>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";
import Chips from "primevue/chips";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Setores",
  components: {
    Dialog,
    Button,
    InputText,
    Chips,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      setores: [],
      showDialog: false,
      setorSelecionado: {},
      headers: [
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "nome"
        },
        {
          text: "IP impressora",
          align: "start",
          sortable: false,
          value: "impressora_ip"
        },
        {
          text: "IP porta",
          align: "start",
          sortable: false,
          value: "impressora_porta"
        },
        {
          text: "Imprimir",
          align: "start",
          sortable: false,
          value: "imprimir"
        },
        {
          text: "Vias",
          align: "start",
          sortable: false,
          value: "vias"
        },
        {
          text: "Ações",
          align: "center",
          sortable: false,
          value: "acoes"
        }
      ],
      filtros: {
        setor: []
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 5,
        first: 0
      },
      setoresNaPagina: []
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },

  mounted() {
    this.getSetores();
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    adicionar() {
      this.setorSelecionado = {
        empresa_id: this.empresaAtual.id
      };
      this.showDialog = true;
    },
    editar(categoria) {
      this.setorSelecionado = categoria;
      this.showDialog = true;
    },
    fecharDialog() {
      this.showDialog = false;
    },
    submit() {
      this.$refs.btnSubmit.$el.click();
    },
    async searchSetores() {

      if (this.filtros.setor.length > 1) {
        this.filtros.setor = [this.filtros.setor[1]];
        await this.getSetores();
      }

      const search = this.filtros.setor[0]?.toUpperCase() || "";

      if (search) {
        this.setores= this.setores.filter(item => item.nome.toUpperCase().includes(search));
        this.pagination.total = this.setores?.length;
        this.setPagina(this.pagination.page);
      } else {
        await this.getSetores();
      }

    },
    salvar() {
      const id = this.setorSelecionado?.id;
      this.$axios
        .post("setor", this.setorSelecionado)
        .then(response => {
          this.setorSelecionado = response.data;
          this.fecharDialog();
          this.getSetores();

          this.$toast.add({
            severity: "success",
            summary: id ? `Setor atualizado com sucesso.` : `Setor cadastrado com sucesso.`,
            life: 2000
          });

        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Cadastrar",
            text: error.response.data
          });
        });
    },

    async getSetores() {
      this.loadingSkeleton = true;

      if (!this.empresaAtual || !this.empresaAtual.id) return;
      this.setores = [];
      await this.$axios
        .get(`/setor/empresa/${this.empresaAtual.id}`, {
          headers: {
            filtros: JSON.stringify({
              setor: this.filtros.setor.map(p => p.trim())
            })
          }
        })
        .then(async response => {
          this.setores = response.data;
          this.pagination.total = this.setores?.length;
          this.setPagina(this.pagination.page);
        })
        .catch(error => {
          this.setores = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha o carregar setores",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },

    setPagina(page) {
      const startIndex = (page - 1) * this.pagination.limit;
      const endIndex = startIndex + this.pagination.limit;
      this.setoresNaPagina = this.setores.slice(startIndex, endIndex); // Atualiza os setores da página atual
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.setor.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

  }
};
</script>

<style></style>
