<template>
  <v-container fluid>
    <v-row class="mobile">
      <div class="espacado">
        <MultiSelect
          v-model="filtros.status"
          :options="status"
          @change="getEmpresas(true)"
          optionLabel="name"
          placeholder="Filtro por status"
          display="chip"
        />
      </div>

      <div class="espacado">
        <Chips
          ref="chipcomponente"
          v-model="filtros.empresa"
          separator=","
          @add="getEmpresas(true)"
          @remove="getEmpresas(true)"
          placeholder="Filtro por nome, telefone, email"
        />
      </div>

      <div class="espacado">
        <BotaoBuscarListas @buscar="buscar" />
      </div>

      <v-col class="text-end">
        <v-tooltip bottom v-if="user.permissoes.adm_empresa">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              fab
              dark
              small
              color="primary"
              :to="{ name: 'addEmpresa' }"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <span>Adicionar Empresa</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader
          :loading="loadingSkeleton"
          type="table"
        >
          <v-data-table
              :fixed-header="true"
              :headers="headers"
              :items="empresas"
              hide-default-footer
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                  v-if="STATUS_EMPRESA[item.status]"
                  class="ma-2 text-center"
                  :color="STATUS_EMPRESA[item.status].cor"
                  style="justify-content: center; width: 90%"
                  label
                  text-color="white"
              >
                {{ STATUS_EMPRESA[item.status].texto }}
              </v-chip>
            </template>
            <template v-slot:[`item.cnpj`]="{ item }">
              {{ $masks.cnpj(item.cnpj) }}
            </template>
            <template v-slot:[`item.acoes`]="{ item }">
              <div v-if="item.status === 'AGUARDANDO_CORRECAO_SOCIOS' || item.status === 'AGUARDANDO_CORRECAO' || item.status === 'AGUARDANDO_INFORMACOES'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToConsultPage(item)"
                        type="button"
                    >
                      <v-icon class="mr-3">mdi-magnify</v-icon>
                    </button>
                  </template>
                  <span>Consultar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToEditPage(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
              <v-tooltip v-else bottom>
                <template v-slot:activator="{ on, attrs }">
                  <button
                      v-bind="attrs"
                      v-on="on"
                      @click="goToConsultPage(item)"
                      type="button"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </button>
                </template>
                <span>Consultar</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-skeleton-loader>
        <div class="text-end">
          <v-btn type="button" @click="exportar" icon>
            <v-icon> mdi-download </v-icon>
          </v-btn>
        </div>
        <v-pagination
          v-model="pagination.page"
          :length="totalPaginas"
          @input="next"
          :total-visible="10"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapMutations, mapState } from "vuex";
import MultiSelect from "primevue/multiselect";
import Chips from "primevue/chips";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Empresas",
  components: {
    MultiSelect,
    Chips,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: true,
      empresas: [],
      carregando: false,
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      headers: [
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "CNPJ",
          align: "start",
          sortable: false,
          value: "cnpj"
        },
        {
          text: "Razão Social",
          align: "start",
          sortable: false,
          value: "razao_social"
        },
        {
          text: "Nome Fantasia",
          align: "start",
          sortable: false,
          value: "nome_fantasia"
        },
        {
          text: "Telefone",
          align: "start",
          sortable: false,
          value: "telefone"
        },
        {
          text: "Email",
          align: "start",
          sortable: false,
          value: "email"
        },
        {
          text: "Ações",
          align: "end",
          sortable: false,
          value: "acoes"
        }
      ],
      dialogConfirm: {
        showDialog: false,
        title: "",
        text: "",
        confirma: Function,
        confirmaText: "",
        cancela: Function
      },
      filtros: {
        status: this.$store.state.filtrosEmpresas.status,
        empresa: this.$store.state.filtrosEmpresas.empresa
      },
      status: [
        { name: "Ativa", code: "ATIVA" },
        { name: "Inativa", code: "INATIVA" },
        { name: "Suspensa", code: "SUSPENSA" },
        { name: "Em análise", code: "AGUARDANDO_APROVACAO" },
        {
          name: "Em análise de compliance",
          code: "AGUARDANDO_APROVACAO_COMPLIANCE"
        },
        {
          name: "Corrigir quadro societário",
          code: "AGUARDANDO_CORRECAO_SOCIOS"
        },
        { name: "Falta Informações", code: "AGUARDANDO_INFORMACOES" },
        { name: "Negado", code: "NEGADO" },
        { name: "Negado por compliance", code: "NEGADO_COMPLIANCE" },
        { name: "Corrigir dados", code: "AGUARDANDO_CORRECAO" },
        { name: "Bloqueada", code: "BLOQUEADA" },
        {
          name: "Aguardando assinatura de contrato",
          code: "AGUARDANDO_ASSINATURA_CONTRATO"
        },
        {
          name: "Aguardando analise compliance manual",
          code: "AGUARDANDO_ANALISE_COMPLIANCE_MANUAL"
        },
        {
          name: "Credenciando no SPB",
          code: "CREDENCIAMENTO_SPB"
        },
        {
          name: "Criando conta pagamento",
          code: "CRIANDO_CONTA_PAGAMENTO"
        }
      ]
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  mounted() {
    this.getEmpresas();
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    goToEditPage(empresa) {
      this.$router.push({
        name: "editEmpresa",
        params: {
          idEmpresa: empresa.id
        }
      });
    },

    async getEmpresas(filtro = false) {

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosEmpresas", {
        status: this.filtros.status,
        empresa: this.filtros.empresa
      });

      this.empresas = [];
      return await this.$axios
        .get(`/empresa`, {
          headers: {
            filtros: JSON.stringify({
              empresa: this.filtros.empresa.map(f => f.trim()),
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit
          }
        })
        .then(async response => {
          this.empresas = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.empresas = [];
          this.openDialogMessage({
            type: "error",
            title: "Não foi possivel buscar as empresas",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getEmpresas();
    },
    async exportar() {
      return await this.$axios
        .get(`/empresa`)
        .then(response => {
          this.toCSVeDownload("empresas.csv", response.data.data);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro ao buscar cobranças",
            text: error.response.data
          });
        });
    },
    goToConsultPage(empresa) {
      this.$router.push({
        name: "viewEmpresa",
        params: {
          idEmpresa: empresa.id
        }
      });
    },
    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.empresa.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: 900px) {
  .mobile {
    display: block;
  }
}
</style>
