<template>
  <v-container fluid>
    <form @submit.prevent="getProdutos(true)">
      <v-row>
        <div class="espacado">
          <MultiSelect
            v-model="filtros.status"
            :options="status"
            @change="getProdutos(true)"
            optionLabel="name"
            placeholder="Filtro por status"
            display="chip"
          />
        </div>
        <div class="espacado">
          <Chips
            ref="chipcomponente"
            v-model="filtros.produto"
            separator=","
            @add="getProdutos(true)"
            @remove="getProdutos(true)"
            placeholder="Filtro por nome, código e descrição"
          />
        </div>
        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>
        <v-col class="text-end">
          <v-tooltip bottom v-if="user.permissoes.adm_servico">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="primary"
                :to="{ name: 'addProduto' }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicionar</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              :headers="headers"
              :fixed-header="true"
              :items="produtos"
              :options="{ itemsPerPage: pagination.limit }"
              hide-default-footer
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                  class="ma-2 text-center"
                  :color="statusStyle(item.status)"
                  style="justify-content: center; min-width: 100px"
                  label
                  text-color="white"
              >
                {{ item.status }}
              </v-chip>
            </template>

            <template v-slot:[`item.valor`]="{ item }">
              {{
                item.valor
                    | formatMoney({
                  minimumFractionDigits: 2
                })
              }}
            </template>

            <template v-slot:[`item.categoria`]="{ item }">
              {{ item.categoria?.nome }}
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToConsultPage(item)"
                        type="button"
                    >
                      <v-icon class="mr-3">mdi-magnify</v-icon>
                    </button>
                  </template>
                  <span>Consultar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToEditPage(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-pagination
      v-model="pagination.page"
      :length="totalPaginas"
      @input="next"
      :total-visible="10"
      :loading="carregando"
    ></v-pagination>
  </v-container>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import Chips from "primevue/chips";
import MultiSelect from "primevue/multiselect";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  name: "Produtos",
  components: {
    Chips,
    MultiSelect,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      produtos: [],
      filtros: {
        produto: this.$store.state.filtrosProdutos.produto,
        status: this.$store.state.filtrosProdutos.status
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },

      carregando: false,
      headers: [
        {
          text: "Status",
          align: "center",
          sortable: false,
          value: "status"
        },
        {
          text: "Código Barras",
          align: "center",
          sortable: false,
          value: "codigo_barras"
        },
        {
          text: "Nome",
          align: "start",
          sortable: false,
          value: "nome"
        },
        {
          text: "Descricao",
          align: "start",
          sortable: false,
          value: "descricao"
        },
        {
          text: "Valor",
          align: "start",
          sortable: false,
          value: "valor"
        },
        {
          text: "Categoria",
          align: "start",
          sortable: false,
          value: "categoria"
        },
        {
          text: "Ações",
          align: "end",
          sortable: false,
          value: "acoes"
        }
      ],
      status: [
        { name: "Ativo", code: "ATIVO" },
        { name: "Inativo", code: "INATIVO" }
      ]
    };
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },

  mounted() {
    this.getProdutos();
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getProdutos(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosProdutos", {
        produto: this.filtros.produto,
        status: this.filtros.status,
      });

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      await this.$axios
        .get(`/produto/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              produto: this.filtros.produto.map(s => s.trim()),
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(async response => {
          this.produtos = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.produtos = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar produtos",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getProdutos();
    },

    goToConsultPage(item) {
      this.$router.push({
        name: "viewProduto",
        params: {
          id: item.id
        }
      });
    },

    goToEditPage(item) {
      this.$router.push({
        name: "editProduto",
        params: {
          id: item.id
        }
      });
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.produto.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

  }
};
</script>

<style></style>
