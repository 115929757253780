<template>
  <v-container fluid>
    <form @submit.prevent="getPlanos(true)">
      <v-row>
        <div class="espacado">
          <MultiSelect
            v-model="filtros.status"
            :options="status"
            @change="getPlanos(true)"
            optionLabel="name"
            placeholder="Filtro por status"
            display="chip"
          />
        </div>
        <div class="espacado">
          <Chips
            ref="chipcomponente"
            v-model="filtros.planos"
            separator=","
            @add="getPlanos(true)"
            @remove="getPlanos(true)"
            placeholder="Filtro por ID e descrição"
          />
        </div>
        <div class="espacado">
          <BotaoBuscarListas @buscar="buscar" />
        </div>
        <v-col class="text-end">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                fab
                dark
                small
                color="primary"
                :to="{ name: 'addPlano' }"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
            <span>Adicionar Plano</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </form>
    <v-row>
      <v-col>
        <v-skeleton-loader
            :loading="loadingSkeleton"
            type="table"
        >
          <v-data-table
              :headers="headers"
              :items="planos"
              :loading="carregando"
              loading-text="Carregando dados"
              :fixed-header="true"
              class="fill-height"
              width="500px"
              :options="{ itemsPerPage: pagination.limit }"
              hide-default-footer
          >
            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                  class="ma-2 text-center"
                  :color="statusStyle(item.status)"
                  style="justify-content: center; min-width: 100px"
                  label
                  text-color="white"
              >
                {{ item.status }}
              </v-chip>
            </template>
            <template v-slot:[`item.valor`]="{ item }">
              {{ calculaTotal(item) | formatMoney({ minimumFractionDigits: 2 }) }}
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
              <div>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToConsultPage(item)"
                        type="button"
                    >
                      <v-icon class="mr-3">mdi-magnify</v-icon>
                    </button>
                  </template>
                  <span>Consultar</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <button
                        v-bind="attrs"
                        v-on="on"
                        @click="goToEditPage(item)"
                        type="button"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </button>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>

          </v-data-table>
        </v-skeleton-loader>
      </v-col>
    </v-row>
    <v-pagination
      v-model="pagination.page"
      :length="totalPaginas"
      :loading="carregando"
      @input="next"
      :total-visible="10"
    ></v-pagination>
  </v-container>
</template>

<script>
import {mapMutations, mapState} from "vuex";
import Chips from "primevue/chips";
import MultiSelect from "primevue/multiselect";
import BotaoBuscarListas from "@/components/BotaoBuscarListas.vue";

export default {
  components: {
    Chips,
    MultiSelect,
    BotaoBuscarListas
  },
  data() {
    return {
      loadingSkeleton: false,
      planos: [],
      carregando: false,
      headers: [
        {
          text: "Status",
          align: "left",
          sortable: false,
          value: "status"
        },
        {
          text: "Id",
          align: "left",
          sortable: true,
          value: "id"
        },
        {
          text: "Descrição",
          align: "left",
          sortable: true,
          value: "descricao"
        },
        {
          text: "Dias de degustação",
          align: "left",
          sortable: false,
          value: "dias_degustacao"
        },
        {
          text: "Dias de validade",
          align: "left",
          sortable: false,
          value: "dias_validade"
        },
        {
          text: "Valor",
          align: "left",
          sortable: false,
          value: "valor"
        },
        {
          text: "Ações",
          align: "end",
          sortable: false,
          value: "acoes"
        }
      ],
      filtros: {
        planos: this.$store.state.filtrosPlanos.planos,
        status: this.$store.state.filtrosPlanos.status
      },
      pagination: {
        page: 1,
        total: 0,
        limit: 10,
        first: 0
      },
      status: [
        { name: "Ativo", code: "ATIVO" },
        { name: "Inativo", code: "INATIVO" }
      ]
    };
  },
  mounted() {
    this.getPlanos();
  },
  computed: {
    ...mapState(["empresaAtual", "user"]),
    totalPaginas() {
      return Math.ceil(this.pagination.total / this.pagination.limit);
    }
  },
  methods: {
    ...mapMutations([
      "openDialogMessage",
      "openDialogConfirm",
      "closeDialogConfirm"
    ]),
    async getPlanos(filtro = false) {

      this.loadingSkeleton = true;

      if(filtro) {
        this.pagination.first = 0;
        this.pagination.page = 1;
      }

      this.$store.commit("setFiltrosPlanos", {
        planos: this.filtros.planos,
        status: this.filtros.status,
      });

      if (!this.empresaAtual || !this.empresaAtual.id) return;

      this.$axios
        .get(`/plano/empresa/${this.empresaAtual.id}/listar`, {
          headers: {
            filtros: JSON.stringify({
              plano: this.filtros.planos.map(p => p.trim()),
              status: this.filtros.status.map(m => m.code)
            })
          },
          params: {
            first: this.pagination.first,
            limit: this.pagination.limit,
            ordem: "DESC"
          }
        })
        .then(response => {
          this.planos = response.data.data;
          this.pagination.total = response.data.count;
        })
        .catch(error => {
          this.planos = [];
          this.openDialogMessage({
            type: "error",
            title: "Falha ao buscar planos",
            text: error.response.data
          });
        })
        .finally(() => {
          this.loadingSkeleton = false;
        });
    },
    calculaTotal(plano) {
      let total = 0.0;

      plano.itens.forEach(item => {
        total =
          parseFloat(total) +
          (parseFloat(item.quantidade * item.valor) - item.desconto);
      });

      return total.toFixed(2);
    },
    next(page) {
      this.pagination.page = page;
      this.pagination.first = (page - 1) * this.pagination.limit;
      this.getPlanos();
    },

    goToConsultPage(item) {
      this.$router.push({
        name: "viewPlano",
        params: {
          idPlano: item.id
        }
      });
    },

    goToEditPage(item) {
      this.$router.push({
        name: "editPlano",
        params: {
          idPlano: item.id
        }
      });
    },

    buscar() {
      const chip = this.$refs.chipcomponente;
      const newValue = chip.$refs.input.value;

      if(newValue) {
        this.filtros.planos.push(newValue);
        chip.$refs.input.value = "";
        chip.$emit("add", newValue);
      } else {
        chip.$emit("add");
      }
    },

  }
};
</script>

<style></style>
