<template>
  <v-container>
    <v-dialog persistent :width="widthDialog" v-model="show">
      <div class="card-form dialog-cliente" style="background-color: #0b7ad1">
        <v-row dense class="mx-auto">
          <v-col>
            <v-btn
                fab
                small
                text
                style="float: right"
                @click="fecharDialog"
            ><v-icon color="white">mdi-close</v-icon></v-btn>
          </v-col>
        </v-row >
        <v-row dense class="mx-auto mt-n8 mb-3">
          <v-col class="d-flex justify-center"><h1 class="white--text title">{{ titulo }}</h1></v-col>
        </v-row>
        <v-row dense>
          <v-col cols="12">
            <v-card color="white" class="pt-5 px-10">
              <form @submit.prevent="continuar()">
                <v-row dense class="mx-auto" justify="center">
                  <v-col cols="12" :sm="form.tipo === 'NAO_INFORMADO' ? 12 : 6">
                    <label for="tipo" class="black--text">Tipo</label>
                    <select
                        v-model="form.tipo"
                        id="tipo"
                        class="card-input__input -select"
                        data-card-field
                        required
                        @change="handleChangeTipoPessoa"
                    >
                      <option
                          v-for="(pessoa, index) in tipos" :key="index" :value="pessoa.valor">
                        <span> {{ pessoa.texto }} </span>
                      </option>
                    </select>
                  </v-col>
                  <v-col v-if="form.tipo === 'PESSOA_FISICA'" cols="12" sm="6">
                    <label for="cpf" class="black--text">CPF</label>
                    <input
                        id="cpf"
                        class="card-input__input"
                        v-model="form.cpf"
                        @keyup="form.cpf = $masks.cpf(form.cpf)"
                        @blur="checkCpfCnpj(form.cpf, 'cpf')"
                        required
                    />
                    <span v-if="errors.cpf" class="red--text text-caption">{{ errors.cpf }}</span>
                  </v-col>
                  <v-col v-if="form.tipo === 'PESSOA_JURIDICA'" cols="12" sm="6">
                    <label for="cnpj" class="black--text">CNPJ</label>
                    <input
                        id="cnpj"
                        class="card-input__input"
                        v-model="form.cnpj"
                        @keyup="form.cnpj = $masks.cnpj(form.cnpj)"
                        @blur="checkCpfCnpj(form.cnpj, 'cnpj')"
                        required
                    />
                    <span v-if="errors.cnpj" class="red--text text-caption">{{ errors.cnpj }}</span>
                  </v-col>
                </v-row>
                <v-row dense class="mx-auto" justify="center">
                  <v-col cols="12" sm="12" v-if="form.tipo === 'PESSOA_FISICA'" >
                      <label for="nome" class="black--text">Nome</label>
                      <input
                          id="nome"
                          class="card-input__input"
                          v-model="form.nome"
                          @required="form.tipo === 'PESSOA_FISICA'"
                      />
                  </v-col>
                  
                  <v-col  cols="12" sm="12" v-if="form.tipo === 'PESSOA_JURIDICA'" >
                      <label for="razao_social" class="black--text">Razão Social</label>
                      <input 
                          id="razao_social"
                          class="card-input__input"
                          v-model="form.razao_social"
                          @required="form.tipo === 'PESSOA_JURIDICA'"
                      />
                  </v-col>
                </v-row>
                <v-row dense class="mx-auto" justify="center">
                  <v-col cols="12" sm="6">
                    <label for="email" class="black--text">E-mail</label>
                    <input
                        id="email"
                        class="card-input__input"
                        v-model="form.email"
                        required
                        @blur="checkEmail"
                    />
                    <span v-if="errors.email" class="red--text text-caption">{{ errors.email }}</span>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <label for="celular" class="black--text">Celular</label>
                    <input
                        id="celular"
                        class="card-input__input"
                        v-model="form.telefone_celular"
                        @keyup="form.telefone_celular = $masks.telefone(form.telefone_celular)"
                        required
                        @blur="checkCelular"
                    />
                    <span v-if="errors.celular" class="red--text text-caption">{{ errors.celular }}</span>
                  </v-col>
                </v-row>
                <v-row dense class="mx-auto" justify="center">
                  <v-col cols="12" sm="4">
                    <label for="cep" class="black--text">CEP</label>
                    <input
                        id="cep"
                        class="card-input__input"
                        v-model="form.cep"
                        @keydown="form.cep = $masks.cep(form.cep)"
                        @focusout="getCepCliente"
                        required
                    />
                  </v-col>
                  <v-col cols="12" sm="8">
                    <label for="rua" class="black--text">Rua</label>
                    <input
                        id="rua"
                        class="card-input__input"
                        v-model="form.endereco"
                        required
                    />
                  </v-col>
                </v-row>
                <v-row dense class="mx-auto" justify="center">
                  <v-col cols="12" sm="3">
                    <label for="numero" class="black--text">Número</label>
                    <input
                        id="numero"
                        class="card-input__input"
                        v-model="form.numero"
                        required
                    />
                  </v-col>
                  <v-col cols="12" sm="5">
                    <label for="complemento" class="black--text">Complemento</label>
                    <input
                        id="complemento"
                        class="card-input__input"
                        v-model="form.complemento"
                    />
                  </v-col>
                  <v-col cols="12" sm="4">
                    <label for="bairro" class="black--text">Bairro</label>
                    <input
                        id="bairro"
                        class="card-input__input"
                        v-model="form.bairro"
                        required
                    />
                  </v-col>
                </v-row>
                <v-row dense class="mx-auto" justify="center">
                  <v-col cols="12" sm="9">
                    <label for="cidade" class="black--text">Cidade</label>
                    <input
                        id="cidade"
                        class="card-input__input"
                        v-model="form.cidade"
                        required
                    />
                  </v-col>
                  <v-col cols="12" sm="3">
                    <label for="estado" class="black--text">Estado</label>
                    <input
                        id="estado"
                        class="card-input__input"
                        v-model="form.uf"
                        required
                    />
                  </v-col>
                </v-row>
                <v-row dense class="mx-auto" justify="center">
                  <v-col cols="12" xl="4" lg="4" md="4" sm="12" class="mb-3">
                    <button class="card-form__button btConfirma" type="submit">
                      Continuar
                    </button>
                  </v-col>
                </v-row>
              </form>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-dialog>
  </v-container>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "DialogDadosClientePagamento",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    titulo: {
      type: String
    },
    cliente: {
      type: Object,
      default: () => ({})
    },
    isBoleto: {
      type: Boolean,
      default: false
    },
    cancela: Function
  },
  data() {
    return {
      showDialog: true,
      widthDialog: "36%",
      errors: {
        cpf: null,
        cnpj: null,
        email: null,
        celular: null
      },
      tipos: [],
      form: {
        nome: null,
        razao_social:null,
        email: null,
        cpf: null,
        cnpj: null,
        telefone_celular: null,
        cep: null,
        endereco: null,
        numero: null,
        complemento: null,
        bairro: null,
        cidade: null,
        uf: null,
        codigo_ibge: null,
        tipo: "PESSOA_FISICA"
      },
    }
  },
  mounted(){
    this.getDadosCliente();
    this.getTipos();
    this.responsive();
  },
  computed:{
    ...mapState(["empresaAtual", "user"]),
  },
  methods:{
    ...mapMutations(["openDialogMessage"]),
    handleView() {
      if(window.innerWidth < 1100) {
        this.widthDialog = "70%";
      }

      if(window.innerWidth < 500) {
        this.widthDialog = "100%";
      }
    },

    responsive() {
      this.handleView();
      window.addEventListener("resize", this.handleView);
    },
    getTipos() {
      this.tipos = this.isBoleto ? this.TIPO_PESSOA.slice(0, -1) : this.TIPO_PESSOA;
    },
    continuar() {

      if(this.form.cpf && !this.validaCPF(this.form.cpf)) {
        this.errors.cpf = "CPF inválido!";
        return;
      }

      if(this.form.cnpj && !this.validarCNPJ(this.form.cnpj)) {
        this.errors.cnpj = "CNPJ inválido!";
        return;
      }

      if(this.form.tipo == 'PESSOA_JURIDICA'){
        this.form.nome = this.form.razao_social.replace(
              /\b(\w+)\b(?=\s+\w+\s)/g,
              (match, palavra, offset, string) => {
                  const nomes = string.split(' ');
                  if (match === nomes[0] || match === nomes[nomes.length - 1]) {
                      return match;
                  }
                  return `${match.charAt(0)}.`;
              }
          );
      }

      if (Object.values(this.errors).every(value => value === null)) {
        this.$emit("dadosCliente", this.form);
      } else {
        return;
      }



    },

    checkCelular() {
      if(this.form.telefone_celular && (this.form.telefone_celular.length > 13 || this.form.telefone_celular.length < 13)) {
        this.errors.celular = "Celular inválido!";
        return;
      } else {
        this.errors.celular = null;
        return;
      }
    },

    checkEmail() {
      if(this.form.email && !this.validarEmail(this.form.email)) {
        this.errors.email = "E-mail inválido!";
        return;
      } else {
        this.errors.email = null;
        return;
      }
    },

    getCepCliente() {
      if (!this.form.cep) {
        return;
      }
      this.form.cep = this.form.cep.replaceAll(".", "");
      if (this.form.cep.length <= 8) {
        this.openDialogMessage({
          type: "error",
          title: "Erro Validação",
          text: "CEP inválido"
        });
        this.form.cep = "";
        return;
      }

      this.$axios
          .get(`/public/endereco/${this.form.cep}`)
          .then(response => {
            if (response.data.erro) return;
            this.form.endereco = response.data.logradouro;
            this.form.bairro = response.data.bairro;
            this.form.cidade = response.data.localidade;
            this.form.uf = response.data.uf;
            this.form.codigo_ibge = response.data.ibge;

            const number = document.getElementById('numero');
            number.focus();

          })
          .catch(() => {
            this.openDialogMessage({
              type: "error",
              title: "Erro Validação",
              text: "CEP não encontrado"
            });
          });
    },

    getDadosCliente() {

      if (Object.keys(this.cliente).length > 0) {
        this.cliente.cep = this.$masks.cep(this.cliente.cep);
        this.cliente.cpf = this.$masks.cpf(this.cliente.cpf);
        this.cliente.telefone_celular = this.$masks.telefone(this.cliente.telefone_celular);
        Object.assign(this.form, this.cliente);
      }

    },

    handleChangeTipoPessoa() {
      this.errors.cpf = null;
      this.errors.cnpj = null;
    },

    checkCpfCnpj(value, tipo) {
      if(value) {

        this.errors.cpf = null;
        this.errors.cnpj = null;

        if(tipo === 'cpf' && value.length !== 14) {
          this.errors.cpf = "CPF inválido!";
          return;
        }

        if(tipo === 'cnpj' && value.length !== 18) {
          this.errors.cnpj = "CNPJ inválido!";
          return;
        }

      }
    },

    fecharDialog() {
      this.$emit("fechar-dialog")
    }
  }

}
</script>
<style lang="scss" scoped>
@import "@/assets/cartao.scss";

.dialog-cliente {
  overflow-x: hidden!important;
  overflow-y: hidden!important;
}

.title {
  font-size: 1.4rem;
}

</style>
