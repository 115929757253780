<template>
  <div class="pa-2 card" :style="{ borderLeft: `5px solid ${color}` }">
    <v-row no-gutters>
      <span class="font-italic text-subtitle-1 text-start" :style="{ color: color }">
        {{ titulo }}
      </span>
    </v-row>
    <v-col class="d-flex centralizado green--text">
      <div>
        <span class="caption"  :style="{ color: color }">R$</span>
        <span class="text-h3"  :style="{ color: color }">
          {{
            totalBrutoRecebivel | formatMoney({ minimumFractionDigits: 2 })
          }}
        </span>
      </div>
      
    </v-col>
    <div class="centralizado">
      <span class="detalhes grey--text font-weight-regular">
        Total de recebiveís de cartão de crédito
      </span>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import EventBus from "@/plugins/evento"

export default {
  data() {
    return {
      totalBrutoRecebivel: 0,
      titulo: "Disponível para antecipação",
      color: "#1b907d"
    };
  },
  computed: {
    ...mapState(["empresaAtual"])
  },
  created() {
    this.getRecebiveis();
    EventBus.$on('reload', this.getRecebiveis);
  },
  beforeDestroy() {
    EventBus.$off('reload', this.getRecebiveis);
  },
  methods: {
    ...mapMutations(["openDialogMessage"]),
    getRecebiveis() {
      this.$axios
        .get(
          `/recebivel/empresa/${this.empresaAtual.id}/total-recebiveis-antecipavel`
        )
        .then(response => {
          this.totalBrutoRecebivel = response.data.total;
          this.$emit("total-bruto-recebivel", response.data.total);
        })
        .catch(error => {
          this.openDialogMessage({
            type: "error",
            title: "Erro - Consultar os recebíveis",
            text: error.response.data
          });
        });
    }
  }
};
</script>
<style scoped>

@media (min-width: 900px) {
  .card {
    min-height: 8.5rem;
  }

  .detalhes{
    font-size: 0.8rem;
  }
}
</style>
