<template>
  <div>
    <DialogLoadginOverlay :showDialog="appOverlay" />

    <Toast position="top-right" />

    <div key="appControll">
      <v-app v-if="$route.meta.public">
        <router-view></router-view>
      </v-app>
      <v-app v-else>
        <v-navigation-drawer v-model="menuOpen" temporary app>
          <Menu></Menu>
        </v-navigation-drawer>

        <v-app-bar app color="primary">
          <AppBar />
        </v-app-bar>

        <v-main>
          <v-container fluid>
            <BreadCrumbs />
            <transition name="slideToRight">
              <router-view :key="viewControll"></router-view>
            </transition>
          </v-container>
        </v-main>
      </v-app>
      <AppLoading :appLoading="appLoading" />
      <DialogMessage :params="dialogMessageParams" />
      <DialogConfirm :params="dialogConfirmParams" />

      <EmpresasDialog
        persistent
        :showDialog="showEmpresasDialog"
        @cancela-dialog="cancelaEmpresaDialog"
        @empresa-selecionada="empresaSelecionada"
      />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import AppBar from "@/components/Layout/AppBar.vue";
import Menu from "@/components/Layout/Menu.vue";
import BreadCrumbs from "@/components/Layout/BreadCrumbs.vue";
import AppLoading from "@/components/Dialogs/AppLoading.vue";
import DialogMessage from "@/components/Dialogs/DialogMessage.vue";
import DialogConfirm from "@/components/Dialogs/DialogConfirm.vue";
import DialogLoadginOverlay from "@/components/Dialogs/DialogLoadingOverlay.vue";
import EmpresasDialog from "@/components/Dialogs/EmpresasDialog.vue";
import Toast from "primevue/toast";

import { mapState } from "vuex";
export default Vue.extend({
  name: "App",
  components: {
    AppBar,
    Menu,
    BreadCrumbs,
    AppLoading,
    DialogMessage,
    DialogConfirm,
    DialogLoadginOverlay,
    EmpresasDialog,
    Toast
  },

  async beforeCreate() {
    this.appOverlay = true;
    let hostDomain;

    if (process.env.NODE_ENV.trim() === "development") {
      hostDomain = "sandbox.sistematize.me";
    } else {
      hostDomain = window.location.host;
    }

    await this.$store
      .dispatch("getConfig", hostDomain)
      .then(() => {
        //Configurando o tema da aplicação
        this.$vuetify.theme.themes.light.primary = this.config.colors.primary;
        this.$vuetify.theme.themes.light.secondary = this.config.colors.secondary;
        this.$vuetify.theme.themes.light.error = this.config.colors.error;
        this.$vuetify.theme.themes.light.info = this.config.colors.info;
        this.$vuetify.theme.themes.light.success = this.config.colors.success;
        this.$vuetify.theme.themes.light.warning = this.config.colors.warning;
        this.$vuetify.theme.themes.light.btConfirma = this.config.colors.btn_confirma;
        this.$vuetify.theme.themes.light.btCancela = this.config.colors.btn_cancela;
        this.$vuetify.theme.themes.light.cardRecebiveis = this.config.colors.cards.recebiveis;
        this.$vuetify.theme.themes.light.cardVendas = this.config.colors.cards.primary;
        this.$vuetify.theme.themes.light.cardEstornos = this.config.colors.cards.primary;
        this.$vuetify.theme.themes.light.cardFalhas = this.config.colors.cards.primary;
        document.title = this.config.nome;
        const link = document.createElement("link");
        link.rel = "icon";
        link.href = this.config.images.favicon;
        const head = document.getElementsByTagName("head");

        head[0].appendChild(link);
      })
      .catch(error => {
        this.$router.push({ name: "TemaNaoConfigurado" });
      });
    this.appOverlay = false;
  },
  beforeMount() {
    const empresaAtualLocal = localStorage.getItem(
      process.env.VUE_APP_LOCAL_KEY
    );
    if (empresaAtualLocal) {
      const empresa = JSON.parse(empresaAtualLocal);
      if (empresa && empresa.id) {
        this.$store.commit("setEmpresaAtual", empresa);
      }
    }
  },
  computed: {
    ...mapState([
      "empresaAtual",
      "authenticated",
      "appLoading",
      "showEmpresasDialog",
      "dialogMessageParams",
      "dialogConfirmParams",
      "config"
    ]),

    menuOpen: {
      get() {
        return this.$store.getters.isMenuOpen;
      },
      set(newValue) {
        this.$store.commit("openMenu", newValue);
      }
    }
  },
  watch: {
    empresaAtual() {
      this.viewControll++;
    },
    authenticated() {
      this.appControll++;
    }
  },
  methods: {
    empresaSelecionada(empresa) {
      this.$store.commit("showEmpresasDialog", false);

      if (!empresa || !empresa.id) return;

      const storeEmpresa = {
        id: empresa.id,
        nome_fantasia: empresa.nome_fantasia,
        razao_social: empresa.razao_social,
        cnpj: empresa.cnpj,
        emite_nfse: empresa.emite_nfse,
        emite_nfce: empresa.emite_nfce
      };

      this.$store.commit("setEmpresaAtual", storeEmpresa);
    },
    cancelaEmpresaDialog() {
      this.$store.commit("showEmpresasDialog", false);
    }
  },

  data: () => ({
    viewControll: 1,
    appControll: 1,
    appOverlay: true
  }),
  created() {
    document.documentElement.setAttribute("lang", "pt-br");
  },
});
</script>
<style>
html {
  overflow-y: auto !important;
}
body::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
  height: 100px;
}

body::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}

body::-webkit-scrollbar-thumb {
  background-color: #2e2e2e; /* color of the scroll thumb */
}

html,
#app {
  color: #424242;
}

.slideToRight-enter-active {
  animation: slideToRight 0.2s ease;
}

.p-component.p-inputtext,
.p-component.p-inputnumber-input,
.p-component.p-dropdown {
  width: 100%;
}

.espacado {
  margin: 0.5rem;
}
.p-multiselect {
  min-width: 15rem;
  width: 100%;
}
.p-chips {
  min-width: 20rem;
  display: flex;
  flex-direction: column;
}

@keyframes slideToRight {
  0% {
    transform: translateX(-900px);
  }
  100% {
    transform: translateX(0);
  }
}

.fa-style {
  font-size: 1.5em;
}

.v-data-table__wrapper {
  color: #495057 !important;
}

.p-chips .p-chips-multiple-container {
  flex-wrap: wrap;
}

.p-column-title {
  font-size: 0.75rem;
  font-weight: normal;
}

.centralizado {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.centralizado-linha {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 8px;
}

.input-big .p-inputnumber-input {
  font-size: 2.5rem;
  text-align: center;
}
.p-toast-message.p-toast-message-success .p-toast-message-icon,
.p-toast-message.p-toast-message-success .p-toast-icon-close {
  color: #34b189 !important;
}

.p-toast-message.p-toast-message-success {
  background: #e5f8f0 !important;
  border-color: #34b189 !important;
  color: #34b189 !important;
}

ul, ol {
    list-style-type: none; /* Remove os marcadores */
    margin: 0;            /* Remove a margem padrão */
    padding: 0;           /* Remove o preenchimento padrão */
}
</style>
